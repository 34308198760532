import productionLines from './productionLines';
import features from './features';
import equipments from './equipments'
// import equipmentTypes from './equipmentTypes';

// const fillProductionLine = (array) => {
//   return productionLines.map((line) => {
//     return {
//       check: array.indexOf(line) !== -1,
//       label: line,
//       count: 1
//     }
//   })
// }
const fillFeatures = (type, array) => {
  return features[type].map((feature) => ({
      ...feature,
      check: array.indexOf(feature.alias) !== -1,
    })
  )
}

const fillEquipments = (array, type='equipments') => {
  return equipments.filter(equipment => {
    if (type === 'gadget') {
      return ((array.indexOf(equipment.alias) !== -1) && (equipment.type.indexOf('gadget') + 1))
    } else {
      return array.indexOf(equipment.alias) !== -1
    }
  })
}

const fillAdditionalEquipments = array => {
  return array.map(item => {
    let currentEquipment = equipments.filter(equipment => ((equipment.alias === item.alias) && !(equipment.type.indexOf('gadget') + 1)))[0]
    return {
      ...currentEquipment,
      count: item.count,
    }
  })
}

const workplaceTeplate = {
  name: '',
  gadget: '',
  equipments: [],
  features: [],
}


const restaurantWorkplaceTemplate = {
  ...workplaceTeplate,
  name: 'Место расчета гостей',
  gadget: fillEquipments([
    'pos_АТОЛ_NFD10_PRO'
  ],'gadget'),
  equipments: fillEquipments([
    'magnet_card_scanner',
    'ksa_titan'
  ]),
  features: fillFeatures('work_place',[
    'feature_pos_interface',
    'autoprint_fiskal_check',
    'cards_for_discounts_and_employees',
  ])
}

const cafeWorkplaceTemplate = {
  ...workplaceTeplate,
  name: 'Место расчета гостей',
  gadget: fillEquipments([
    'notebook_Name'
  ],'gadget'),
  equipments: fillEquipments([
    // 'magnet_card_scanner',
    'ksa_titan'
  ]),
  features: fillFeatures('work_place',[
    'feature_pos_interface',
    'autoprint_fiskal_check',
    // 'cards_for_discounts_and_employees',
  ])
}

const coffee_houseWorkplaceTemplate = {
  ...workplaceTeplate,
  name: 'Место расчета гостей',
  gadget: fillEquipments([
    'tablet_SMSUNG_10'
  ],'gadget'),
  equipments: fillEquipments([
    // 'magnet_card_scanner',
    // 'ksa_titan'
  ]),
  features: fillFeatures('work_place',[
    // 'feature_pos_interface',
    // 'autoprint_fiskal_check',
    // 'cards_for_discounts_and_employees',
  ])
}

const productionLineTemplate = {
  name: 'Производственная линия',
  equipments: fillEquipments([
    'excpos_printer_Name'
  ]),
  features: fillFeatures('production_line',[
    'recieve_tasks'
  ])
}

export default [
  // 
  {
    alias: 'empty',
    notDisplay: true,
    name: ' ',
    defaultTemplates: {
      workPlaces: cafeWorkplaceTemplate,
      productionLines: productionLineTemplate
    },
    default: {
      typePlace: 'empty',
      workPlaces: [],
      productionLines: [],
      additionalEquipments: {
        features: fillFeatures('additional_equipments',[]),
        equipments: []
      }
    }
  },
  {
    alias: 'restaurant',
    name: 'Ресторан',
    defaultTemplates: {
      workPlaces: restaurantWorkplaceTemplate,
      productionLines: productionLineTemplate
    },
    default: {
      typePlace: 'restaurant',
      // features: fillFeatures('common',[
      //   'autoprint_fiskal_check',
      //   'cards_for_discounts_and_employees',
      //   'send_tasks_to_production_lines',
      //   // 'stock_accaunting'
      // ]),
      workPlaces: Array.from(new Array(2)).map((item,index) => {
        return {
            ...restaurantWorkplaceTemplate,
            name: restaurantWorkplaceTemplate.name + ` #${index + 1}`
          }
        }),
      productionLines: Array.from(new Array(1)).map((item,index) => {
        return {
          ...productionLineTemplate,
          name: productionLines[index] ? productionLines[index] : productionLineTemplate.name + ` #${index + 1}`
        }
      }),
      additionalEquipments: {
        features: fillFeatures('additional_equipments',[
          'mobile_work_places'
        ]),
        equipments: fillAdditionalEquipments([
          {
            alias: 'tablet_SMSUNG_10',
            count: 3
          }
        ])
      }
    }
  },
  {
    alias: 'cafe',
    name: 'Кафе',
    defaultTemplates: {
      workPlaces: cafeWorkplaceTemplate,
      productionLines: productionLineTemplate
    },
    default: {
      typePlace: 'cafe',
      // features: fillFeatures('common',[
      //   'autoprint_fiskal_check',
      //   // 'cards_for_discounts_and_employees',
      //   // 'send_tasks_to_production_lines',
      //   // 'stock_accaunting'
      // ]),
      workPlaces: Array.from(new Array(1)).map((item,index) => {
        return {
            ...cafeWorkplaceTemplate,
            name: cafeWorkplaceTemplate.name + ` #${index + 1}`
          }
        }),
      productionLines: Array.from(new Array(1)).map((item,index) => {
        return {
          ...productionLineTemplate,
          name: productionLines[index] ? productionLines[index] : productionLineTemplate.name + ` #${index + 1}`
        }
      }),
      additionalEquipments: {
        features: fillFeatures('additional_equipments',[
          // 'mobile_work_places'
        ]),
        equipments: fillAdditionalEquipments([

        ])
      }
      
    }
  },
  {
    alias: 'coffee_house',
    name: 'Кoфейня',
    defaultTemplates: {
      workPlaces: coffee_houseWorkplaceTemplate,
      productionLines: productionLineTemplate
    },
    default: {
      typePlace: 'coffee_house',
      // features: fillFeatures('common',[
      //   // 'autoprint_fiskal_check',
      //   // 'cards_for_discounts_and_employees',
      //   // 'send_tasks_to_production_lines',
      //   // 'stock_accaunting'
      // ]),
      workPlaces: Array.from(new Array(1)).map((item,index) => {
        return {
            ...coffee_houseWorkplaceTemplate,
            name: coffee_houseWorkplaceTemplate.name + ` #${index + 1}`
          }
        }),
      productionLines: Array.from(new Array(0)).map((item,index) => {
        return {
          ...productionLineTemplate,
          name: productionLines[index] ? productionLines[index] : productionLineTemplate.name + ` #${index + 1}`
        }
      }),
      additionalEquipments: {
        features: fillFeatures('additional_equipments',[
          // 'mobile_work_places'
        ]),
        equipments: fillAdditionalEquipments([
        ])
      }
      
    }
  },
]