import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Divider from '@material-ui/core/Divider';
import CardMenu from './CardMenu';
import CardFeatures from './CardFeatures';
import CardEquipments from './CardEquipments';
import CardPrice from './CardPrice';
// import CardPrice from './CardPrice';

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1,2,0,2),
    
  },
  headerAction: {
    marginTop: 0,
    marginRight: theme.spacing(-1),
    
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  contentRoot: {
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
    
    
  }
}),{
  name: 'CalculatorItemCard'
});



const CalculatorItemCard = (props) => {
  const classes = useStyles();

  const { 
    item, 
    type,
    itemIndex 
  } = props;


  return (
    <Card>
      <CardHeader
        classes={{
          root: classes.headerRoot, 
          title: classes.title,
          action: classes.headerAction
        }}
        title={item.name}
        action={<CardMenu itemIndex={itemIndex} itemType={type} itemName={item.name}/>}
      />
      <CardContent
        classes={{
          root: classes.contentRoot
        }}
      >
        {/* <Divider /> */}
        <CardFeatures features={item.features} itemIndex={itemIndex} itemType={type}/>
        {/* <Divider /> */}
        <CardEquipments item={item} itemIndex={itemIndex} itemType={type}/>
        <Divider />
        <CardPrice item={item}/>
      </CardContent>
    </Card>
  );
}

export default CalculatorItemCard;