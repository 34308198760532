import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSkype,
  faViber,
  faTelegramPlane,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowRight,
  faPhone,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

import contacts from '../data/contacts';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 180,
    }
  },
  icon: {
    fontSize: '1.5em'
  },
  iconSmall: {
    fontSize: '1em',
    margin: '3.5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em',
      margin: '1.5px'
    }
  },
  arrow: {
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    color: '#444',
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 300,
    padding: 5,
    margin: '0 5px',
    '&:last-child': {
        marginRight: 0
    },
    '&:hover, &:focus': {
        backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  text: {
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
}
  
}),{
  name: 'HeaderContaсts'
});


const HeaderContaсts = () => {
  let classes = useStyles();
  return (
    <div className={classes.root}>
      <a className={classes.item} href={`mailto:${contacts.email}`} aria-label="Электронная почта">
        {/* <FontAwesomeIcon className="header-contacts__icon header-contacts__icon_with-mr header-contacts__icon_small" color='rgba(0,0,0,0.5)' icon={faEnvelope}/> */}
        <FontAwesomeIcon className={classes.iconSmall} color='rgba(0,0,0,0.5)' icon={faEnvelope}/> 
        <span className={classes.text}>{contacts.email}</span>
      </a>
      <a className={classes.item} href={`skype:${contacts.skype}?chat`} aria-label="Skype">
        <FontAwesomeIcon className={classes.icon} size="1x" color="#00AFF0" icon={faSkype}/> 
        <span className={classes.text}>{contacts.skype}</span>
      </a>
      <a className={classes.item} href={`tel:${contacts.phone}`} aria-label="Телефонный номер">
        <FontAwesomeIcon className={classes.iconSmall} color='rgba(0,0,0,0.5)' icon={faPhone}/> 
        <span className={classes.text}>{contacts.displayPhone}</span>
      </a>
      <FontAwesomeIcon className={classes.arrow} size="sm" color='rgba(0,0,0,0.1)' icon={faArrowRight}/>
      <a className={classes.item} href={`viber://chat?number=${contacts.viber}`} aria-label="Viber">
        <FontAwesomeIcon className={classes.icon} size="1x" color="#7360f2" icon={faViber}/>
      </a>
      <a className={classes.item} href={`tg://resolve?domain=${contacts.telegram}`} aria-label="Telegram">
        <FontAwesomeIcon className={classes.icon} size="1x" color="#2ca4df" icon={faTelegramPlane}/>
      </a>
      <a className={classes.item} href={`whatsapp://send?phone=${contacts.phone}`} aria-label="WhatsApp">
        <FontAwesomeIcon className={classes.icon} size="1x" color="#25D366" icon={faWhatsapp}/>
      </a>
    </div>
  )
}

export default HeaderContaсts;