const features = {
  common: [
    {
      alias:'autoprint_fiskal_check',
      label:'Автоматическая печать кассовых чеков',
      shortLabel: 'Печать чеков',
      helper: 'Для того, чтобы вам не дублировать ввод информации о чеке из программы на кассовый аппарат мы предлагаем вам использовать данный фукнционал. Данная функция автоматизирует эту операцию и кассовый аппарат сам распечатает фискальный чек и отправит его в СКНО',
      can_be_multiple:false,
      dependences: {
        once_service: [
          'hw_proxy'
        ],
        card_features: [
          'autoprint_fiskal_check'
        ]
        // equipmentTypes: [
        //   'ksa'
        // ]
      }
    },
    {
      alias:'cards_for_discounts_and_employees',
      label:'Использовать магнитные карты для скидок и персонала',
      shortLabel: 'Магнитные карты',
      helper: 'Использование данной функции позволяет вам использовать магнитные карты(на которых есть мангнитная полоса, как на банковских картах) для скидок и персонала',
      can_be_multiple:false,
      dependences: {
        once_service: [
          'hw_proxy'
        ],
        card_features: [
          'cards_for_discounts_and_employees'
        ]
      }
    },
    {
      alias:'send_tasks_to_production_lines',
      label:'Отправка заданий на производственные линии (Бар, Кухня, Пицца...)',
      shortLabel: 'Отправка заданий',
      helper: 'Для того, чтобы доставлять пожелания гостей сразу на места приготовления мы предлагаем воспользоваться данной функцией. Задания можно печатать на чековом принтере, или, если у вас небольшое заведение, печатать сразу на кассовом аппарате.',
      can_be_multiple:false,
      dependences: {
        once_service: [
          'hw_proxy'
        ],
        card_features: [
          'recieve_tasks'
        ]
      }
    },
  ],
  work_place: [
    {
      alias:'autoprint_fiskal_check',
      label:'Автоматическая печать кассовых чеков',
      shortLabel: 'Печать чеков',
      helper: 'Для того, чтобы вам не дублировать ввод информации о чеке из программы на кассовый аппарат мы предлагаем вам использовать данный фукнционал. Данная функция автоматизирует эту операцию и кассовый аппарат сам распечатает фискальный чек и отправит его в СКНО',
      can_be_multiple:false,
      dependences: {
        services: [
          'pos_interface',
          'hw_proxy'
        ],
        equipmentTypes: [
          'ksa'
        ]
      }
    },
    {
      alias:'cards_for_discounts_and_employees',
      label:'Использовать магнитные карты для скидок и персонала',
      shortLabel: 'Магнитные карты',
      helper: 'Для того, чтобы доставлять пожелания гостей сразу на места приготовления мы предлагаем воспользоваться данной функцией. Задания можно печатать на чековом принтере, или, если у вас небольшое заведение, печатать сразу на кассовом аппарате.',
      can_be_multiple:false,
      dependences: {
        services: [
          'pos_interface',
          'hw_proxy'
        ],
        equipmentTypes: [
          'magnet_scanners'
        ]
      }
    },
  ],
  production_line: [
    {
      alias:'recieve_tasks',
      label:'Получение заданий на этой линии',
      shortLabel: 'Получение заданий',
      helper: 'Для того, чтобы доставлять пожелания гостей сразу на места приготовления мы предлагаем воспользоваться данной функцией. Задания можно печатать на чековом принтере, или, если у вас небольшое заведение, печатать сразу на кассовом аппарате.',
      dependences: {
        once_services: [
          'hw_proxy'
        ],
        equipmentTypes: [
          'task_transfers'
        ]
      }
    },
  ],
  additional_equipments: [
    {
      alias:'mobile_work_places',
      label:'Планшеты для официантов',
      shortLabel: 'Планшеты у официантов',
      helper: 'Если вы хотите, чтобы ваши официанты могли набрать счет сразу, при общении с гостем, то мы предлагаем воспользоваться данной функцией.',
      dependences: {
        services: [
          'pos_interface'
        ],
        equipmentTypes: [
          'mobile_workplace'
        ]
      }
    },
  ]
}


export default features;