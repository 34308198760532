import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

import './fonts/GothamPro2/stylesheet.css';

const nonBreakSpace = (text) => {
  return text.replace(/ /g, String.fromCharCode(160))
}

let theme = createMuiTheme({
  palette: {
    primary: {main: orange[500]},
    secondary: {main: orange[300]},
  },
  typography: {
    nonBreakSpace: nonBreakSpace,
    fontFamily: [
      '"Gotham Pro"', 
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // '@font-face': GothamPro['@font-face'],
        html: {
          scrollBehavior: 'smooth',
        },
        div: {
          outline: 0
        }
      },
    },
  },
  sizes: {
    header: {
      height: {
        xs: 300,
        sm: 186,
        md: 245,
        lg: 181,
        xl: 181,
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export { theme };