import React from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import CallToAction from './CallToAction';
import MainTitle from './MainTitle';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: data => `url('${data.backgroundImage}')`,
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat no-repeat',
    backgroundSize: 'cover',
    padding: '100px 0',
    zIndex: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10,0,0,0),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5,0,0,0),
    },
    '&:before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      content: '""',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: -1
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '30%',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '40%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingRight: theme.spacing(3),
    }
  },
  list: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: 40,
    // backgroundColor: 'rgba(0,0,0,0.35)',
    borderRadius: 10,
    // boxShadow: theme.shadows[6]

  },
  listItem: {
    marginBottom: 20,
    color: '#fff',
    textDecoration: 'none',
    // borderBottom: `1px solid rgba(255,255,255,0.2)`,
    paddingBottom: 2,
    fontSize: 24,
    fontWeight: 400,
    textShadow: '-1px 1px 4px rgba(0,0,0,0.75)',
    [theme.breakpoints.down('lg')]: {
      fontSize: 22,
      marginBottom: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    '&:last-child': {
      marginBottom: 0
    },
    '&:hover': {
      borderBottomColor: 'rgba(255,255,255,0.6)'
    }
  },
  callToAction: {
    position: 'absolute',
    backgroundColor: fade(theme.palette.primary.main, 0.8),
    transform: 'skew(-20deg) translateX(20%)',
    top: 0,
    right: 0,
    bottom: 0,
    width: '48%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(25),
    boxShadow: theme.shadows[8],
    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      transform: 'none',
      padding: theme.spacing(2),
      width: 'auto',
      position: 'relative',
      top: 'auto',
      right: 0,
      bottom: 0,
      marginTop: theme.spacing(5),
      
      marginRight: -theme.spacing(3),
      marginLeft: -theme.spacing(3),
      
    },
    '& > div': {
      transform: 'skew(20deg)',
      [theme.breakpoints.down('md')]: {
        transform: 'none'
      }
    }
  }

}), {
  name: 'TopBlock'
});


const TopBlock = ({data,tiles,calculatorRoot}) => {
  let classes = useStyles(data);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <MainTitle 
          homePage
          text={data.title}
          color="white"
        />
        <nav className={classes.list}>
          {(tiles) && tiles.map( (item, index) => (
            <a className={classes.listItem} key={`advantage-${index}`} href={`#${item.id}`}>{item.title}</a>
          ))}
        </nav>
        <div className={classes.callToAction}>
          <CallToAction data={data.callToAction} calculatorRoot={calculatorRoot}/>
        </div>
      </Container>
    </div>
  )
}

export default TopBlock;