import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CardHelper from '../CalculatorItemCard/CardHelper';


import { toggleFeatureAdditionalEquipments } from '../../../store/actions/calculatorActions';

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: '100%',
    padding: theme.spacing(0,0,2,0),
    fontSize: '0.9em'
  },
  item: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    }
  },
  formGroup: {
    flexGrow: 1
  },
  switch: {
    marginRight: theme.spacing(1),
  },
  label: {
    lineHeight: 1.1,
    fontSize: '1.05em'
  }
}),{
  name: 'FeaturesList'
});



const FeaturesList = (props) => {
  const classes = useStyles();
  
  const { 
    featuresList,
    toggleFeature
  } = props;

  const handleChange = featureIndex => () => {
    toggleFeature(featureIndex)
  }

  return (
    <div className={classes.root}>
      {featuresList.map((line,featureIndex) => (
        <div className={classes.item} key={`add-features-${featureIndex}`}>
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              classes={{
                label: classes.label
              }}
              control={
                <Switch
                  classes={{
                    root: classes.switch
                  }}
                  checked={line.check}
                  size="small"
                  onChange={handleChange(featureIndex)}
                  value={line.alias}
                  color="primary"
                />
              }
              label={line.label}
            />
          </FormGroup>
          <CardHelper title={line.label} text={line.helper}/>
        </div>
      ))}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFeature: (index) => {dispatch(toggleFeatureAdditionalEquipments(index))}
  }
};
export default connect(
  null,
  mapDispatchToProps
)(FeaturesList)