import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';

import CalculatorBlock from './CalculatorBlock';
import DepartmentForm from './DepartmentForm';
// import FeaturesList from './FeaturesList';
// import CalculatorSwitchList from '../Calculator/CalculatorSwitchList';
import WorkPlacesList from './WorkPlacesList';
import ProductionLinesList from './ProductionLinesList';
import AdditionalEquipmentsList from './AdditionalEquipmentsList';
import CalculatorTotal from './CalculatorTotal';

const useStyles = makeStyles(theme => ({
  root: {

    // backgroundColor: '#0f0',
    // border: '1px solid #ccc'
  },

}),{
  name: 'CalculatorBody'
});

const CalculatorBody = (props) => {
  let classes = useStyles();
  
  const {
    template,
    calculatorRoot
  } = props;

  
  return (
    <div className={classes.root}>
      <CalculatorBlock title="Выберите шаблон для того, чтобы мы могли вам сформировать предварительную конфигурацию. В дальнейшем вы можете изменить любой параметр." noneMinHeight>
        <DepartmentForm />
      </CalculatorBlock>
      {/* <CalculatorBlock title="Возможности" noneMinHeight>
        <FeaturesList />
      </CalculatorBlock> */}
      <Collapse in={template !== 'empty'}>
        <CalculatorBlock title="Рабочие места" addButtonType="workPlaces" bgColor="grey">
          <WorkPlacesList/>
        </CalculatorBlock>
        <CalculatorBlock title="Производственные линии" addButtonType="productionLines" >
          <ProductionLinesList />
        </CalculatorBlock>
        <CalculatorBlock title="Доп. оборудование">
          <AdditionalEquipmentsList />
        </CalculatorBlock>
      </Collapse>
      
      <CalculatorTotal calculatorRoot={calculatorRoot}/>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    template: state.calculator.output.typePlace
  };
};

export default connect(
  mapStateToProps,
  null
)(CalculatorBody)