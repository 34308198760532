// import React from "react";
// import imageWebp from '../../theme/images/horeca.webp';

import priceImage from '../../theme/images/gateway/price.jpg';
import teamViewerImage from '../../theme/images/gateway/teamviewer.png';
import networkImage from '../../theme/images/gateway/network.png';
import printImage from '../../theme/images/gateway/print.gif';
import nolimitImage from '../../theme/images/gateway/nolimit.jpg';
import controlImage from '../../theme/images/gateway/control.jpg';
import securityImage from '../../theme/images/gateway/security.png';
import itImage from '../../theme/images/gateway/it.gif';

// import one_c_logo from '../../theme/images/one_c/one_c_logo.png'

import multi_devices from '../../theme/images/gateway/devices.jpg'

import backgroundImageGateway from '../../theme/images/freelance.jpg';

import default_og_image from '../../theme/images/default-og-image.jpg';
export default {
  title: 'Удаленная работа ваших сотрудников со шлюзом «О, удобно»',
  meta_title: 'Удаленная работа ваших сотрудников со шлюзом «О, удобно» по цене от 500 руб.',
  meta_description: 'Шлюз «О, удобно» - недорогой способ перевести сотрудников на удаленную работу. Без ежемесячных платежей. Без лимитов по количеству одновременных сеансов. Подключение с любого устройства с браузером. Безопасность данных. Контроль сотрудников. Имеет ценность для технических специалистов',
  meta_keywords: 'Удаленка, переход на удаленку, переход на удаленную работу',
  url: '/',
  url_image: default_og_image,
  topBlock: {
      title: 'Шлюз «О, удобно» - недорогой способ перевести сотрудников на удаленную работу',
      backgroundImage: backgroundImageGateway,
      callToAction: {
          title: 'от 500 руб.',
          buttonDemo: {
              title: 'Демо',
              action: 'https://gateway.oodobno.by'
          },
          buttonOffer: {
              title: 'Подробнее о стоимости'
          }
      }
  }, // end topBlock
  tabs: {
      title: 'Проблемы, с которыми можно столкнуться при переводе работников на удаленную работу ',
      description: `
При всей своей нынешней доступности и, казалось бы, простоте, организация удаленного доступа для пользователя, который не подкован технически, до сих пор является не самой простой задачей.

Почему? Давайте разберемся по пунктам:
      `,
      items: [
          {
              tabName: 'Установка приложения на компьютере',
              title: 'Скачать программу из интернета и установить ее самостоятельно на компьютер - уже проблема',
              description: `
Если вы когда-нибудь сталкивались с необходимостью дистанционного подключения к компьютеру пользователя - то знаете, насколько это не просто. 

Да, на сегодняшний день уже многие знакомы с программами наподобие TeamViewer, но слышать и самостоятельно зайти на сайт и установить - это не одно и тоже. 

Вам, как специалисту поддержки, приходится тратить уйму времени и нервов на эти действия. А сколько проблем доставляет то, что надо следить за актуальностью версий этих программ. Да и самому пользователю не хочется чувствовать свою беспомощность. 

**Мы предлагаем - просто поделиться ссылкой, и всё.**

Пользователь открывает ее в браузере и может приступать к работе. Вы можете выслать ее с помощью любого мессенджера или электронной почты.
              `,
              media: [
                  {
                      thumbnail: teamViewerImage,
                      path: teamViewerImage,
                      type: 'image',
                      label: 'TeamViewer - платный',
                  }
              ]
          },
          {
              tabName: 'Печать с удаленного компьютера',
              title: 'Печать из сеанса RDP',
              description: `
До сих пор эта проблема остаётся актуальной. Особенно если у вас разные архитектуры или версии Windows на компьютере, за которым вы работаете, и компьютере к которому вы подключены. Также существует просто огромное количество принтеров, которые вы без “танцев с бубном” просто не запустите для нормальной печати из RDP сеанса.

**Мы предлагаем - вы просто скачиваете PDF файл из браузера и отправляете его на любой принтер, который к вам подключен.**

Мы можем настроить даже автоматическую распечатку.
              `,
              media: [
                  {
                    thumbnail: printImage,
                    path: printImage,
                    label: 'Печать из нашего шлюза',
                    type: 'image'
                  }
              ]
          },
          {
              tabName: 'Неподготовленность инфраструктуры',
              title: 'У большинства компаний сетевая инфраструктура не подготовлена для удаленной работы',
              description: `
Во многих компаниях никогда не задумывались об удаленной работе сотрудников, и решения выбирались без учета данной потребности.

На офисе установлено множество программ, которые привязаны к компьютеру:

* клиент-банки (веб-банки)
* системы учета
* личные кабинеты систем общения с органами власти
* хранилища документов

**Наше решение решение избавляет Вас от необходимости дополнительной настройки всех этих программ.**

По сути мы доставлем рабочее место сотрудника ему домой.
              `,
              media: [
                {
                  thumbnail: networkImage,
                  path: networkImage,
                  // label: 'Список продуктов',
                  type: 'image'
                }
              ]
          },
          {
            tabName: 'И много "небольших" проблем',
            title: 'В процессе эксплуатации у пользователей возникает много вопросов',
            description: `
У нас реализована загрузка и выгрузка файлов в обе стороны. Для быстрой загрузки пользователю достаточно просто положить файл в каталог Downloads на диске Z и браузер тут же предложит его скачать. А при нажатии Ctrl+Shift+Alt на компьютере или свайпом с левого края на мобильный устройствах вы попадет в меню, где сможете:

* загрузить файл на удаленный компьютер
* поделиться одноразовой ссылкой на сеанс  с другим человеком, это может быть удобно для демонстрации экрана
* выбрать экранную клавиатуру и способ взаимодействия для мобильных устройств
            
          `,
          media: [
            {
              thumbnail: networkImage,
              path: networkImage,
              // label: 'Список продуктов',
              type: 'image'
            }
          ]
        }
      ]
  }, // end tabs
  callToActionBlock: {
      title: 'Есть вопросы?',
      button: {
          title: 'Получите бесплатную консультацию',
          action: ''
      }
  },
  moreTiles: [
    {
      id: 'price',
      title: 'Без ежемесячных платежей',
      description: `
Мы используем решение с открытыми исходными кодами, это позволяет нам предлагать это решение без ежемесячных платежей.

У большинства конкурентов (например TeamViewer и AnyDesk) Вы оплачиваете аренду одновременных сеансов. При этом минимальный платеж за год вперед.
      `,
      media: [
        {
          thumbnail: priceImage,
          path: priceImage,
          type: 'image',
          label: 'Без ежемесячных платежей',
        }
      ]
    },
    {
      id: 'no-limit',
      title: 'Без лимитов по количеству одновременных сеансов',
      description: `
Наше решение устанавливается внутри вашей локальной сети на один из компьютеров (даже не обязательно выделять отдельный ПК).

Мы не храним его на своих серверах. Это решение будет принадлежать Вам, соотвественно количество подключений и одновременных сессий никто не ограничит.

Права владения наступают после полной оплаты.
      `,
      media: [
        {
          thumbnail: nolimitImage,
          path: nolimitImage,
          type: 'image',
          label: 'Без лимитов по количеству подключений и одновременных сеансов',
        }
      ]
    },
    {
      id: 'any-devices',
      title: 'Подключение с любого устройства с браузером',
      description: `
Подключиться к своему рабочему компьютеру можно с любого устройства, даже со смартфона.

Это полезно и при временной работе на "удаленке", и после окончания карантинных мер.

Сотрудники смогут быть более полезными компании на больничных, в командировках и на выездных переговорах.
      `,
      media: [
        {
          thumbnail: multi_devices,
          path: multi_devices,
          label: 'Работает на любых устройствах',
          type: 'image'
        },
      ]
    },
    {
      id: 'security',
      title: 'Безопасность данных',
      description: `
Мы используем современные методы защиты - SSL сертификаты. Все соединения ПРИНУДИТЕЛЬНО проходят по протоколу https. Данный уровень безопасности является международным стандартом и применяется всеми от Google до банков.

Так как конечная система принадлежит Вам и храниться в вашей сети, а не в облаке у поставшика услуги, то и доступом распоряжаетесь тоже Вы.
      `,
      media: [
        {
          thumbnail: securityImage,
          path: securityImage,
          label: 'Безопасность данных',
          type: 'image'
        }
      ]
    },
    {
      id: 'control',
      title: 'Контроль сотрудников',
      description: `
Наша система ведет журнал входов сотрудников через удаленный доступ. Информация обо всех сессиях сохраняется, и Вы всегда можете посмотреть кто и когда начал и закончил работу. 

Есть возможность настроить запись:

* экрана
* движений мышки
* нажимаемых клавиш на клавиатуре

Дополнительно можно настроить для руководителя онлайн мониторинг рабочих мест. При этом не важно будет ли сотрудник работать удаленно или физически за компьютером.
      `,
      media: [
        {
          thumbnail: controlImage,
          path: controlImage,
          label: 'Контроль сотрудников',
          type: 'image'
        }
      ]
    },
    {
      id: 'it',
      title: 'Имеет ценность для технических специалистов',
      description: `
Мы ваши коллеги, и понимаем с какими проблемами вы можете столкнуться или сталкиваетесь каждый день. 

Данное решение является открытым продуктом и может быть самостоятельно реализовано с помощью документации [https://guacamole.apache.org/](https://guacamole.apache.org/). 

Мы же в свою очередь предлагаем уже готовый к употреблению вариант и услуги по первоначальному внедрению. Данный продукт является великолепным решением и позволяет получать доступ через браузер по следующим протоколам:

* SSH
* RDP
* VNC

Установив в свою инфраструктуру данное решение вы получите полный контроль над своими ресурсами в независимости от того, на какой системе они работают. Windows, Linux или MacOS
      `,
      media: [
        {
          thumbnail: itImage,
          path: itImage,
          label: 'Контроль сотрудников',
          type: 'image'
        }
      ]
    },
  ], 
 
  //end moreTiles
  faq: {
    title: 'Часто задаваемые вопросы',
    callToAction: {
      question: 'Не нашли ответ на интересующий Вас вопрос?',
      answer: `Звоните`
    },

    questions: [
      {
        question: 'Что нужно для установки вашей системы?',
        answer: `
Система ставиться на один из компьютеров. Для этого нужно получить к нему удаленный доступ.
От вашего провайдера необзодимо получить услугу "Статический IP адрес"
`
      },
      {
        question: 'Есть ли ограничения по скорости интернета?',
        answer: `
Для комфортной работы нужно чтобы интернет соединения было не менее 512 Кбит/с (0,5 Мбит/с) на отдачу на каждого пользователя.

Протестировать скорость можно здесь [https://yandex.ru/internet/](https://yandex.ru/internet/). Нас интересует *Исходящее соединение*.
`
      },
      {
        question: 'Нужно ли что нибудт настраивать на рабочих компьютерах?',
        answer: `
Да, необходимо будет на всех компьютерах включиьт доступ к нему через RDP. Переименовать пользователя компьютера в латиницу и обязательно установить пароль. (Эти логин и пароль будут розданы пользователям).

Эта настраивается или нами удаленно, или Вами по инструкции.
`
      },
      {
        question: 'Какие технические требования к компьютеру, на который ставиться ваше решение?',
        answer: `
* Не менее 4Гб оперативной памяти.
* Процессор не медленнее Intel Core-i3
* Желателен SSD диск
`
      }
    ]
  },
  price: {
    title: 'Полная стоимость внедрения и владения',
    price_one_time: 500,
    description: `
Наше решение является открытым и мы берем оплату за внедрение готового к употреблению продукта.

**В стоимость входит:**

* Первоначальное внедрение
* Настройка 3-х независимых подключений к 3-м разным компьютером внутри вашего офиса
* Обучающие материалы по самостоятельному администрированию системы и созданию дополнительных подключений

**Мы не берем:**

* Арендную плату за пользование. Заплатив один раз решением можно пользоваться столько, сколько Вам это необходимо.
* Нет лицензионных и программных ограничений по количеству подключений и одновременных сеансов.

**Дополнительные услуги:**

* Настройка системы контроля сотрудников в реальном времени. Цена 350 руб.
* Брендинг системы под Вашу торговую марку. Цена 150 руб.

    `
  }
} //end All horeca