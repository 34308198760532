import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';

import { changeEquipmentAdditionalEquipments, deleteEquipmentAdditionalEquipments, setCountEquipmentAdditionalEquipments } from '../../../store/actions/calculatorActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    
  },
  icon: {
    width: 16,
    height: 16,
    color: 'rgba(0,0,0,0.3)'
  },
  formControl: {
    // flexGrow: 1,
    padding: theme.spacing(0,1)
  },
  select: {
    fontSize: '0.9em'
  },
  trashIcon: {
    marginLeft: theme.spacing(1),
  },
  replaceTrashIcon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1
  },
  price: {
    fontSize: '0.9em',
    margin: theme.spacing(0.5),
  },
  count: {
    width: 30,
    margin: theme.spacing(0.5),
  },
  countInput: {
    fontSize: '1em',
  },
  summa: {
    margin: theme.spacing(0.5),
  }
}),{
  name: 'EquipmentsListItem'
});



const EquipmentsListItem = (props) => {
  const classes = useStyles();
  
  const { 
    equipmentTypes, // список из Redux
    equipments, // список из Redux
    equipment, // элемент для отрисовки из props, поданный в родителе
    equipmentIndex,
    changeEquipment, // Это самое простое, функция из коннекта,
    deleteEquipment,
    setCount
  } = props;

  const handleChange = event => {
    // console.log('Select', event.target.value);
    const newValue = equipments.filter(item => ((item.title === event.target.value) && (item.type === equipment.type)))[0]
    changeEquipment(equipmentIndex,newValue)
  };

  const handleDelete = () => {
    deleteEquipment(equipmentIndex)
  };

  const countInput = useRef()

  const handleCountChange = () => {
    setCount(equipmentIndex,countInput.current.value)
  }


  const Icon = equipmentTypes[equipment.type].icon

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}/>
      <FormControl className={classes.formControl}>
        <Select 
          classes={{
            root: classes.select
          }}
          value={equipment.title} 
          onChange={handleChange} 
          className={classes.selectEmpty}
          disableUnderline
          
        >
          {equipments.filter(item => item.type === equipment.type).map((item,index) => (
            <MenuItem
              value={item.title}
              key={`item-${index}`}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.flexGrow}/>
      <span className={classes.price}> {equipment.price} </span>
      <span className={classes.count}>
        <TextField
          // className={classes.countInput}
          inputRef={countInput}
          id={`count-equipment-${equipmentIndex}`}
          // label="К-во"
          type="number"
          value={equipment.count}
          onChange={handleCountChange}
          InputProps={{
            classes:{
              root: classes.countInput
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </span>
      <span className={classes.summa}> {equipment.count * equipment.price} </span>
      
      {(equipmentTypes[equipment.type].can_delete) ? (
        <Tooltip title={`Удалить ${equipment.title}`}>
          <IconButton aria-label="delete" size="small" className={classes.trashIcon} onClick={handleDelete}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ) : (
        <span className={classes.replaceTrashIcon}/>
      )}
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    equipmentTypes: state.calculator.data.equipmentTypes,
    equipments: state.calculator.data.equipments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeEquipment: (indexEquipment,newValue) => {dispatch(changeEquipmentAdditionalEquipments({indexEquipment,newValue}))},
    deleteEquipment: index => dispatch(deleteEquipmentAdditionalEquipments(index)),
    setCount: (index,value) => dispatch(setCountEquipmentAdditionalEquipments({index,value}))
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentsListItem)