import React, { useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2,0),
    fontSize: '0.9em'
  },
  button: {
    alignSelf: 'center',
    opacity: 0.3,
    '&:hover,&:focus': {
      opacity: 0.8
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: '1.2em',
    lineHeight: '1.2em'
  },
  closeButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-2),
    alignSelf: 'flex-start',
    
    
  }
}),{
  name: 'CardFeatures'
});

const CardHelper = ({title, text}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <IconButton aria-label="delete" className={classes.button} size="small" onClick={handleClick}>
        <HelpIcon fontSize="inherit" />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
      <DialogTitle className={classes.title} disableTypography>
        {title}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>

      </Dialog>
    </React.Fragment>
  )
}


export default CardHelper;