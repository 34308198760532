import templates from "../../data/calculator/templates";

import equipments from "../../data/calculator/equipments";
import equipmentTypes from "../../data/calculator/equipmentTypes";
import services from "../../data/calculator/services";
import sorting from "../../data/calculator/sorting";
import productionLines from "../../data/calculator/productionLines";

const initialState = {
  data: {
    templates,
    equipments,
    equipmentTypes,
    services,
    sorting,
    productionLines
  },
  // output: templates.filter(template => template.alias === 'restaurant')[0].default,
  output: templates.filter(template => template.alias === 'empty')[0].default,
};


export default function calculator(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case '@@calculator/init':
      return {
        state
      };
    case '@@calculator/applyTemplate':
      return {
        ...state,
        output: {
          ...templates.filter(template => template.alias === action.payload)[0].default
        }
      }
    case '@@calculator/changeValue':
      return {
        ...state,
        output: {
          ...state.output,
          [action.payload.name]: action.payload.value
        }
      }
    case '@@calculator/clearData':
      return {
        ...state,
        output: {
          ...templates.filter(template => template.alias === 'empty')[0].default
        }
      }
    case '@@calculator/CHANGE_VALUE':
      return {
        ...state,
        output: {
          ...state.output,
          [action.payload.name]: action.payload.value
        }
      }
    case '@@calculator/TOGGLE_FEATURE':
      newState =  {
        ...state,
        output: {
          ...state.output,
          features: state.output.features.map((line, indexLine) => {
            return {
              ...line,
              check: (indexLine === action.payload) ? !line.check : line.check
            }
          })
        }
      }

      return newState

      case '@@calculator/TOGGLE_CARD_FEATURE':

        // itemType,itemIndex,indexFeature
        let newGadget = false;
        if (state.output[action.payload.itemType][action.payload.itemIndex].gadget) {
          newGadget = state.output[action.payload.itemType][action.payload.itemIndex].gadget[0].hw_proxy ? state.output[action.payload.itemType][action.payload.itemIndex].gadget : [equipments.filter(equipment => equipment.type === 'gadget_pos_interface_and_hw_proxy').sort((a, b) => a.price - b.price)[0]]
        }
        newState = {
          ...state,
          output: {
            ...state.output,
            [action.payload.itemType]: [
              ...state.output[action.payload.itemType].map((item,index) => {
                if (index !== action.payload.itemIndex) {
                  return item
                } else {
                  return {
                    ...item,
                    gadget: newGadget ? [...newGadget] : null,
                    features: item.features.map((feature,indexFeature) => ({
                      ...feature,
                      check: (indexFeature !== action.payload.indexFeature) ? feature.check : !feature.check
                    }))
                  }
                }
              })
            ]
          }
        }
        let currentCardFeature = newState.output[action.payload.itemType][action.payload.itemIndex].features[action.payload.indexFeature]
        if (currentCardFeature.check) {
          let addEquipment = equipments.filter(item => (item.type === currentCardFeature.dependences.equipmentTypes[0]))[0]
          newState.output[action.payload.itemType][action.payload.itemIndex].equipments = [
            ...newState.output[action.payload.itemType][action.payload.itemIndex].equipments,
            addEquipment
          ]
        } else {
          newState.output[action.payload.itemType][action.payload.itemIndex].equipments = [
            ...newState.output[action.payload.itemType][action.payload.itemIndex].equipments.filter(item => (item.type !== currentCardFeature.dependences.equipmentTypes[0]))
          ]
        }
        
        return newState
      case '@@calculator/CHANGE_EQUIPMENT_CARD':
        const {itemType,itemIndex,equipmentType,indexEquipment,newValue} = action.payload
        if ((equipmentType === 'gadget') && (!newValue.hw_proxy)) {
          return {
            ...state,
            output: {
              ...state.output,
              [itemType]: [
                ...state.output[itemType].map((item,index) => {
                  if (index !== itemIndex) {
                    return item
                  } else {
                    return {
                      ...item,
                      features: [
                        ...item.features.map(feature => ({
                          ...feature,
                          check: false
                        }))
                      ],
                      equipments: [],
                      gadget: [
                        ...item.gadget.map((child,childIndex) => {
                          if (childIndex !== indexEquipment) {
                            return child
                          } else {
                            return newValue
                          }
                        })
                      ]
                    }
                  }
                })
              ]
            }
          }
        } else {
          return {
            ...state,
            output: {
              ...state.output,
              [itemType]: [
                ...state.output[itemType].map((item,index) => {
                  if (index !== itemIndex) {
                    return item
                  } else {
                    return {
                      ...item,
                      [equipmentType]: [
                        ...item[equipmentType].map((child,childIndex) => {
                          if (childIndex !== indexEquipment) {
                            return child
                          } else {
                            return newValue
                          }
                        })
                      ]
                    }
                  }
                })
              ]
            }
          }
        }
        
        case '@@calculator/DELETE_EQUIPMENT_CARD':
          newState = {
            ...state,
            output: {
              ...state.output,
              [action.payload.itemType]: [
                ...state.output[action.payload.itemType].map((item,index) => {
                  if (index !== action.payload.itemIndex) {
                    return item
                  } else {
                    return {
                      ...item,
                      [action.payload.equipmentType]: [
                        ...item[action.payload.equipmentType].slice(0, action.payload.indexEquipment),
                        ...item[action.payload.equipmentType].slice(action.payload.indexEquipment + 1)
                      ]
                    }
                  }
                })
              ]
            }
          }
          let deletedEquipmentCard = state.output[action.payload.itemType][action.payload.itemIndex].equipments[action.payload.indexEquipment]
          newState.output[action.payload.itemType][action.payload.itemIndex].features = newState.output[action.payload.itemType][action.payload.itemIndex].features.map(item => ((item.dependences.equipmentTypes.indexOf(deletedEquipmentCard.type) + 1) ? {...item,check: false} : item))
          return newState
        case '@@calculator/ADD_CARD':
          return {
            ...state,
            output: {
              ...state.output,
              [action.payload.itemType]: [
                ...state.output[action.payload.itemType],
                {
                  ...templates.filter(template => template.alias === state.output.typePlace)[0].defaultTemplates[action.payload.itemType],
                  name: templates.filter(template => template.alias === state.output.typePlace)[0].defaultTemplates[action.payload.itemType].name + ` #${state.output[action.payload.itemType].length + 1}`
                }
              ]
            }
          }
        case '@@calculator/DELETE_CARD':
          return {
            ...state,
            output: {
              ...state.output,
              [action.payload.itemType]: [
                ...state.output[action.payload.itemType].slice(0, action.payload.itemIndex),
                ...state.output[action.payload.itemType].slice(action.payload.itemIndex + 1),
              ]
            }
          }
        case '@@calculator/CLONE_CARD':
          return {
            ...state,
            output: {
              ...state.output,
              [action.payload.itemType]: [
                ...state.output[action.payload.itemType],
                {
                  ...state.output[action.payload.itemType][action.payload.itemIndex],
                  name: state.output[action.payload.itemType][action.payload.itemIndex].name + ' (копия)'
                }
                
              ]
            }
          }
        case '@@calculator/CHANGE_NAME_CARD': 
          return {
            ...state,
            output: {
              ...state.output,
              [action.payload.itemType]: [
                ...state.output[action.payload.itemType].map((item,index) => {
                  if (index !== action.payload.itemIndex) {
                    return item
                  } else {
                    return {
                      ...item,
                      name: action.payload.newName
                    }
                  }
                }),
              ]
            }
            
          }
        case '@@calculator/TOGGLE_FEATURE_ADD_EQUIPMENTS':
          newState = {
            ...state,
            output: {
              ...state.output,
              additionalEquipments: {
                ...state.output.additionalEquipments,
                features: [
                  ...state.output.additionalEquipments.features.map((item,itemIndex) => ({
                    ...item,
                    check: (itemIndex === action.payload) ? !item.check : item.check
                  }))
                ]
              }
            }
          }

          let currentAdditionalEquipmentsFeature = newState.output.additionalEquipments.features[action.payload]
        if (currentAdditionalEquipmentsFeature.check) {
          let addEquipment = equipments.filter(item => (item.type === currentAdditionalEquipmentsFeature.dependences.equipmentTypes[0]))[0]
          newState.output.additionalEquipments.equipments = [
            ...newState.output.additionalEquipments.equipments,
            {
              ...addEquipment,
              count: 1
            }
          ]
        } else {
          newState.output.additionalEquipments.equipments = [
            ...newState.output.additionalEquipments.equipments.filter(item => (item.type !== currentAdditionalEquipmentsFeature.dependences.equipmentTypes[0]))
          ]
        }

        return newState

        case '@@calculator/CHANGE_EQUIPMENT_ADD_EQUIPMENTS':
          return {
            ...state,
            output: {
              ...state.output,
              additionalEquipments: {
                ...state.output.additionalEquipments,
                equipments: [
                  ...state.output.additionalEquipments.equipments.map((item,index) => (index !== action.payload.indexEquipment) ? item : {...action.payload.newValue, count: item.count})
                ]
              }
            }
          }
        case '@@calculator/DELETE_EQUIPMENT_ADD_EQUIPMENTS':
          return {
            ...state,
            output: {
              ...state.output,
              additionalEquipments: {
                ...state.output.additionalEquipments,
                equipments: [
                  ...state.output.additionalEquipments.equipments.slice(0, action.payload),
                  ...state.output.additionalEquipments.equipments.slice(action.payload + 1),
                ],
                features: [
                  ...state.output.additionalEquipments.features.map(item => {
                    if (item.dependences.equipmentTypes.indexOf(state.output.additionalEquipments.equipments[action.payload].type) + 1) {
                      return {
                        ...item,
                        check:false
                      }
                    } else {
                      return item
                    }
                  })
                ]
              }
            }
          }

          // let deletedEquipmentAdditionalEquipments = state.output[action.payload.itemType][action.payload.itemIndex].equipments[action.payload.indexEquipment]
          // newState.output[action.payload.itemType][action.payload.itemIndex].features = newState.output[action.payload.itemType][action.payload.itemIndex].features.map(item => ((item.dependences.equipmentTypes.indexOf(deletedEquipmentCard.type) + 1) ? {...item,check: false} : item))

          // return newState
          case '@@calculator/SET_COUNT_EQUIPMENT_ADD_EQUIPMENTS':
            return {
              ...state,
              output: {
                ...state.output,
                additionalEquipments: {
                  ...state.output.additionalEquipments,
                  equipments: [
                    ...state.output.additionalEquipments.equipments.map((item,index) => (index !== action.payload.index) ? item : {...item, count: action.payload.value})
                  ]
                }
              }
            }
   
    // case '@@calculator/rewriteElement':
    //   return {
    //     ...state,
    //     output: {
    //       ...state.output,
    //       [action.payload.inputKey]: [
    //         ...state.output[action.payload.inputKey].map((row,index) => (index === action.payload.index) ? action.payload.row : row),
    //       ]
    //     }
    //   }
    
    default:
      return state
  }
}