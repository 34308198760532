// import React from "react";
// import imageWebp from '../../theme/images/horeca.webp';

import pos_interface_001 from '../../theme/images/pos_interface/pos_interface_001.png'
import pos_interface_002 from '../../theme/images/pos_interface/pos_interface_002.png'
import pos_interface_003 from '../../theme/images/pos_interface/pos_interface_003.png'
import pos_interface_004 from '../../theme/images/pos_interface/pos_interface_004.png'

import manager_interface_001 from '../../theme/images/manager_interface/manager_interface_001.png'
import manager_interface_002 from '../../theme/images/manager_interface/manager_interface_002.png'
import manager_interface_003 from '../../theme/images/manager_interface/manager_interface_003.png'
import manager_interface_004 from '../../theme/images/manager_interface/manager_interface_004.png'
import manager_interface_005 from '../../theme/images/manager_interface/manager_interface_005.png'
import manager_interface_006 from '../../theme/images/manager_interface/manager_interface_006.png'
import manager_interface_007 from '../../theme/images/manager_interface/manager_interface_007.png'

// import titan_a_001 from '../../theme/images/titan_support/titan_a_001.jpg'
// import titan_plus_001 from '../../theme/images/titan_support/titan_plus_001.jpg'

import one_c_logo from '../../theme/images/one_c/one_c_logo.png'

import multi_devices from '../../theme/images/multi_devices/multi_devices.png'
// import dell_xps_13 from '../../theme/images/multi_devices/dell_xps_13.png'
// import nexus_9_horizontal from '../../theme/images/multi_devices/nexus_9_horizontal.png'

import backgroundImageHoreca from '../../theme/images/background.jpg';
import default_og_image from '../../theme/images/default-og-image.jpg';


const pos_interface_description = `
**Рабочее Место Кассира** позволяет осуществлять все необходимые операции:

  * вести несколько счетов на одном столе
  * передать счет другому официату или бармену
  * разделить счет на несколько и оплатить каждый из них отдельно
  * разделить счет по оплате, вы можете принять несколько сумм от гостей и отразить их в чеке оплаты
  * принимать оплату наличными, по карте или смешанным платежом
  * предоставлять скидку, как постоянным клиентам по карте, так и по вашему желанию
  * официанты видят только свои счета, а менеджеры видят все

**Рабочее Место Кассира** может работать на компьютере, ноутбуке или планшете.
`


const manager_interface_description= `
В **Интерфейсе менеджера** Вы можете выполнять операции:

  * создавать продукты, указывать их устанавливать категорию и цену
  * создавать, редактировать и удалять группы товаров, доступных на **Рабочем Месте Кассира**
  * видеть все необходимые цифры в отчетах как по смене, так и за любой нужный период
  * выгружать отчеты в файлы Excel, которые Вы можете распечатывать или отправить по электронной почте
  * указывать причины отказа для удаляения чеков или позиций

**Интерфейс менеджера** может работать на компьютере, ноутбуке или планшете.
`


const hw_proxy_description = `
Данный компонент необходим для работы оборудования внутри вашего учреждения. На текущий момент наш сервер работает со следующимим видами оборудования:

  * чековые принтера по протоколу ESC-POS, с поддержкой следующих интерфейсов
  * USB
  * COM (Serial, RS-232)
  * Ethernet (подключение к локальной сети)
  * весы
  * кассовый аппарат ТИТАН-А
  * сканеры штрих-кодов, магнитных карт и RFID меток
  * обеспечивате работу в режиме (когда есть центральнаая касса и подключенные к ней терминалы)

Данный компонент может работать как на Компьютере, Ноутбуке или Микрокомпьютере.
`

const titan_a_description = `Для того, чтобы ваши сотрудники не совершали двойную работу по оформлению чеков, мы выполнили интеграцию нашей программы с кассовыми аппаратами серии ТИТАН. Данный подход позволил вынести все взаимодействие с кассой в наше приложение. Теперь достаточно нажать одну кнопку, для того, чтобы оформить оплату по чеку. Затем касса сама его расечатает и отправит в СКНО.`


const one_c_description = `Поскольку решения компании 1С имеют широкое распространение на территории нашей страны, мы реализовали различные способы обмена данными между нашей программой и решениями на базе 1C. В связи с тем, что количество варинатов конфигурации велико, то перед внедрением необходимо проконсультироваться с нами.`

const multi_devices_description = `Наше программное обеспечение работает в браузере, что позволяет ему запускаться на любом устройстве, с которого вы можете выйти в Интернет.`


// const description = (
//   <React.Fragment>
//      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
//   </React.Fragment>
// )


export default {
  title: 'Система автоматизации для ресторанов и кафе «О, удобно»',
  meta_title: 'Автоматизация ресторанов и кафе от 15 руб.',
  meta_description: '«О, удобно» - Система автоматизации для ресторанов и кафе.  Поддержка кассовых аппаратов серии ТИТАН. Работает на любом устройстве. Интеграция с 1С',
  meta_keywords: 'Автоматизация, рестораны, кафе, недорого',
  url: '/horeca',
  url_image: default_og_image,
  topBlock: {
      title: 'Система автоматизации для ресторанов и кафе',
      backgroundImage: backgroundImageHoreca,
      callToAction: {
          title: 'от 15 руб.',
          buttonDemo: {
              title: 'Демо доступ',
              action: 'https://demo.yartsev.by'
          },
          buttonOffer: {
              title: 'Рассчитать стоимость'
          }
      }
  }, // end topBlock
  tabs: {
      title: 'Что входит в систему «О, удобно»?',
      items: [
          {
              tabName: 'Рабочее место кассира',
              title: 'Интуитивно понятный интерфейс и быстрое освоение',
              description: pos_interface_description,
              media: [
                  {
                      thumbnail: 'https://img.youtube.com/vi/0hbzHwe93hE/mqdefault.jpg',
                      videoId: '0hbzHwe93hE',
                      type: 'video',
                      label: 'Видео работы с программой',
                  },
                  {
                      thumbnail: pos_interface_001,
                      path: pos_interface_001,
                      label: 'План заведения',
                      type: 'image'
                  },
                  {
                      thumbnail: pos_interface_002,
                      path: pos_interface_002,
                      label: 'Работа со счетом',
                      type: 'image'
                  },
                  {
                      thumbnail: pos_interface_003,
                      path: pos_interface_003,
                      label: 'Работа с клиентом',
                      type: 'image'
                  },
                  {
                      thumbnail: pos_interface_004,
                      path: pos_interface_004,
                      label: 'Оплата счета',
                      type: 'image'
                  },
              ]
          },
          {
              tabName: 'Интерфейс менеджера',
              title: 'Работать можно из любой точки где есть интернет',
              description: manager_interface_description,
              media: [
                  {
                    thumbnail: manager_interface_001,
                    path: manager_interface_001,
                    label: 'Список продуктов',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_002,
                    path: manager_interface_002,
                    label: 'Работа с продуктом',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_003,
                    path: manager_interface_003,
                    label: 'Выручка по смене',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_004,
                    path: manager_interface_004,
                    label: 'Работа с категориями РМК',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_005,
                    path: manager_interface_005,
                    label: 'Отчет по выручке',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_006,
                    path: manager_interface_006,
                    label: 'Скачиваем отчет',
                    type: 'image'
                  },
                  {
                    thumbnail: manager_interface_007,
                    path: manager_interface_007,
                    label: 'Открываем отчет в Excel',
                    type: 'image'
                  },
              ]
          },
          {
              tabName: 'Сервер Работы Оборудования',
              title: 'Широчайший спектр поддерживаемого оборудования',
              description: hw_proxy_description,
              media: [
                  {
                    thumbnail: 'https://img.youtube.com/vi/AbwgZOPdk58/mqdefault.jpg',
                    videoId: 'AbwgZOPdk58',
                    type: 'video',
                    label: 'Видео работы',
                  }
              ]
          }
      ]
  }, // end tabs
  callToActionBlock: {
      title: 'Есть вопросы?',
      button: {
          title: 'Получите бесплатную консультацию',
          action: ''
      }
  },
  moreTiles: [
    {
      id: 'titan',
      title: 'Поддержка кассовых аппаратов серии ТИТАН',
      description: titan_a_description,
      media: [
        {
          thumbnail: 'https://img.youtube.com/vi/xF1afeREjqs/mqdefault.jpg',
          videoId: 'xF1afeREjqs',
          type: 'video',
          label: 'Видео работы системы с кассовым аппратом',
        }
      ]
    },
    {
      id: 'any-devices',
      title: 'Работает на любом устройстве',
      description: multi_devices_description,
      media: [
        {
          thumbnail: multi_devices,
          path: multi_devices,
          label: 'Работает на любых устройствах',
          type: 'image'
        },
      ]
    },
    {
      id: '1c',
      title: 'Интеграция с 1С',
      description: one_c_description,
      media: [
        {
          thumbnail: one_c_logo,
          path: one_c_logo,
          label: 'Логотом фирмы 1С',
          type: 'image'
        }
      ]
    },
  ], //end moreTiles
  faq: {
    title: 'Часто задаваемые вопросы',
    callToAction: {
      question: 'Не нашли ответ на интересующий Вас вопрос?',
      answer: `Звоните`
    },
    questions: [
      {
        question: 'Можно ли использовать старый компьютер?',
        answer: `
Да можно, если он удовлетворяет указанным условиям. На данный момент эта информация понятна только техническим специалистам. Вы всегда можете обратиться к нашим менеджерам

Для экспресс проверки вам необходимо выполнить следующую инструкцию: 

  1. Скачать с официального сайта [LinuxMint](https://www.linuxmint.com/download.php) ISO образ
  2. С помощью программы [Rufus](https://rufus.ie/) записать загруженный образ на флешку
  3. Загрузиться с этой флешки на вашем комьпьютере
  4. Попробуйте подключиться к WiFi или локальной сети и посмотреть видео на Youtube
Если все пункты удалось выполнить, то скорее всего ваш компьютер подходит.
Поскольку работоспосбоность программы зависит в первую очередь от качества оборудования, мы настоятельно рекомендуем не использовать Б/У технику. 
Все работы связанные с устранением неисправностей, причиной которых будет являться выбранное вами Б/У оборудование, вы будете оплачивать отдельно.
`
      },
      {
        question: 'Возможно ли работать со смартфона?',
        answer: `
Технически ограничений нет, но интерфейс на текущий момент не подготовлен для работы на маленьких экранах, однако в экстренном случае это вполне возможно сделать. 

Мы работаем над тем, чтобы можно было полноценно работать со смартфона
`
      },
      {
        question: 'Можно ли использовать оборудование от старой системы учета?',
        answer: `
Да это возможно. 

Но в любом случае вам необходимо проконсультироваться с нами. Это связано с тем, что оборудование может быть слишком специфическим.
`
      },
      {
        question: 'Есть ли у скрытые платежи и другие неявные способы монетизации',
        answer: `
Нет, скрытых платежей у нас нет, мы рекомендуем воспользоваться калькулятором для детального или пообщаться с нашим специалистом.`
      },
    ]
  }
} //end All horeca