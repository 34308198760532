export default [
  {
    alias:"magnet_card_scanner",
    title:"Сканер магнитных карт",
    type:"magnet_scanners",
    term:168,
    price:150.00,
    uom:"шт.",
    dependences: {
      services: [
        'hw_proxy'
      ],
      equipmentTypes: [
        'gadget_hw_proxy'
      ]
    }
  },
  {
    alias:"magnet_card_scanner_2",
    title:"Сканер магнитных карт 2",
    type:"magnet_scanners",
    term:168,
    price:192.00,
    uom:"шт.",
    dependences: {
      services: [
        'hw_proxy'
      ],
      equipmentTypes: [
        'gadget_hw_proxy'
      ]
    }
  },
  {
    alias:"ksa_titan",
    title:"Кассовый аппарат ТИТАН",
    type:"ksa",
    hw_proxy: false,
    pos_interface:false,
    term:168,
    price:250.00,
    uom:"шт.",
  },
  {
    alias:"excpos_printer_Name",
    title:"Чековый принтер EPSON",
    type:"task_transfers",
    term:168,
    price:500.00,
    uom:"шт.",
  },
  {
    alias:"notebook_Name",
    title:"Обычный ноутбук",
    type:"gadget_pos_interface_and_hw_proxy",
    hw_proxy: true,
    pos_interface:true,
    term:168,
    price:600.00,
    uom:"шт.",
  },
  {
    alias:"pos_АТОЛ_NFD10_PRO",
    title:"POS-компьютер АТОЛ",
    type:"gadget_pos_interface_and_hw_proxy",
    hw_proxy: true,
    pos_interface:true,
    term:168,
    price:600.00,
    uom:"шт.",
  },
  {
    alias:"tablet_SMSUNG_10",
    title:"Планшет Samsung",
    type:"gadget_pos_interface",
    hw_proxy: false,
    pos_interface:true,
    term:168,
    price:400.00,
    uom:"шт.",
    dependences: false
  },
  {
    alias:"raspberrypi",
    title:"Малина",
    type:'gadget_hw_proxy',
    hw_proxy: true,
    pos_interface:false,
    term:168,
    price:280.00,
    uom:"шт.",
  },
  {
    alias:"tablet_SMSUNG_10",
    title:"Планшет Samsung",
    type:"mobile_workplace",
    term:168,
    price:400.00,
    uom:"шт.",
    dependences: false
  },
  {
    alias:"tablet_SAMSUNG_11",
    title:"Планшет Samsung 11",
    type:"mobile_workplace",
    term:168,
    price:410.00,
    uom:"шт.",
    dependences: false
  },
]