import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import CalculatorMenuHelp from './CalculatorMenuHelp';
import AddIcon from '@material-ui/icons/Add';

import { addCard } from '../../../store/actions/calculatorActions'

import { withSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: props => props.noneMinHeight ? 'auto' : 300,
    backgroundColor: props => props.bgColor ? theme.palette.grey[300] : 'transparent',
    // border: '1px solid #ccc',
    margin: theme.spacing(0,-2),
  },
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0,2),
    backgroundColor: theme.palette.grey[100],
    zIndex: 1050,
  },
  title: {

  },
  actionsHeader: {
  },
  body: {
    padding: theme.spacing(2,2,6,2)
  },
  addButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.dark,
    }
  }
}),{
  name: 'CalculatorBlock'
});

const CalculatorBlock = (props) => {
  // console.log('props.noneMinHeight',props.noneMinHeight);
  
  let classes = useStyles(props);
  
  const {
    title = 'Заголовок',
    addButtonType,
    addNewCard,
    children,
    enqueueSnackbar
  } = props;

  const handleAddClick = () => {
    addNewCard(addButtonType)
    enqueueSnackbar('Добавлено')
  }
  
  return (
    <section className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.actionsHeader}>
          {addButtonType && (
            <IconButton className={classes.addButton} size="small" onClick={handleAddClick}>
              <AddIcon fontSize="inherit"/>
            </IconButton>
          )}
          <CalculatorMenuHelp />
        </div>
      </div>
      <div className={classes.body}>
        {children}
      </div>
      
    </section>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewCard: (itemType) => {dispatch(addCard({itemType}))}
  }
};
export default connect(
  null,
  mapDispatchToProps
)(withSnackbar(CalculatorBlock))

