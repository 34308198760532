import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';


// import YouTube from 'react-youtube';

import { MobileStepper,
  Paper,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade
  } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableViews from 'react-swipeable-views';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// import { autoPlay } from 'react-swipeable-views-utils';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      justifyContent: 'center',
      paddingLeft: 0
    }
  },
  image: {
    // backgroundImage: activeStep => `url('${activeStep.path}')`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat no-repeat',
    width: 400,
    maxWidth: '100%',
    height: 225,
    cursor: 'pointer',
    '&:hover': {
      '& > svg': {
        width: '3.5em',
        height: '3.5em',
        opacity: 1,
        zIndex: 1010
      }
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
    }
  },
  backdrop: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'block',
      content: '""',
      backgroundColor: 'rgba(0,0,0,0.5)'
    }

  },
  playIcon: {
    color: '#fff',
    opacity: 0.75,
    width: '3em',
    height: '3em',
    transition: 'all 0.3s ease-in-out'
  },
  mobileStepper: {
    backgroundColor: 'transparent'
  }
}),{
  name: 'Carousel'
});

const useStylesModal = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 15px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  image: {
    maxWidth: '100%'
  },
  
  videoIframe: {
    maxWidth: '100%'
  },
  arrowIcon: {
    width: '4em',
    height: '4em'
  },
  closeIcon: {
    width: '3em',
    height: '3em',
    [theme.breakpoints.down('sm')]: {
      width: '1em',
      height: '1em'
    }
  },
  arrowButton: {
    height: '100%',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    color: '#fff'
  },
  
}),{
  name: 'ModalCarousel'
});


const Carousel = ({mediaList,hiddenAlt}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(activeStep);
  const classesModal = useStylesModal(activeStep);
  const theme = useTheme();
  
  const maxSteps = mediaList.length;
  const multiImagesMode = maxSteps > 1

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {mediaList.map((step, index) => {
          switch(step.type) {
            case 'image': return (
              <div className={classes.image} key={step.label} style={{backgroundImage: `url('${step.thumbnail}')`}} onClick={handleOpen} />
            )
            case 'video': return (
              <div className={`${classes.image} ${classes.backdrop}`} key={step.label} style={{backgroundImage: `url('https://img.youtube.com/vi/${step.videoId}/mqdefault.jpg')`}} onClick={handleOpen}>
                <PlayCircleOutlineIcon className={classes.playIcon} onClick={handleOpen}/>
              </div>
            )
            default: return (
              <div className={classes.image} key={step.label} style={{backgroundImage: `url('${step.thumbnail}')`}} onClick={handleOpen} />
            )
          
          }
        }
        
        // (
        //   <div className={classes.image} key={step.label} style={{backgroundImage: `url('${step.thumbnail}')`}} onClick={handleOpen} />
        // )
        )}
      </SwipeableViews>
      {(!hiddenAlt) && (
        <Paper square elevation={0} className={classes.header}>
          <Typography>{mediaList[activeStep].label}</Typography>
        </Paper>
      )}
      
      {multiImagesMode && (
        <MobileStepper
        classes={{root: classes.mobileStepper}}
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        }
      />
      )}
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesModal.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <React.Fragment>
            {/* <Button onClick={handleClose}>X</Button> */}
            {multiImagesMode && (
              <Button size="large" className={classesModal.arrowButton} onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft className={classesModal.arrowIcon} />
              </Button>
            )}
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              onClick={handleClose}
              slideClassName={classesModal.slide}
              enableMouseEvents
            >
              {mediaList.map((step, index) => {
                switch(step.type) {
                  case 'image': return (
                    <img 
                      className={classesModal.image} 
                      alt={step.label} 
                      src={step.path} 
                      key={'bigImage' + step.label}/>
                  )
                  case 'video': return (
                    <iframe 
                      title={step.label}
                      className={classes.videoIframe}
                      width="640" 
                      height="360" 
                      key={'bigVideo' + step.label}
                      src={"https://www.youtube-nocookie.com/embed/" + step.videoId} 
                      frameBorder="0" 
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                      allowFullScreen/>
                  )
                  default: return (
                    <img 
                      className={classesModal.image} 
                      alt={step.label} 
                      src={step.path} 
                      key={'bigImage' + step.label}/>
                  )
                
                }
              })}
            </SwipeableViews>
            {multiImagesMode && (
              <Button size="large" className={classesModal.arrowButton} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                <KeyboardArrowRight className={classesModal.arrowIcon} />
              </Button>
            )}
            
            <Button size="large" className={classesModal.closeButton} onClick={handleClose}>
              <CloseIcon className={classesModal.closeIcon} />
            </Button>
          </React.Fragment>
        </Fade>
      </Modal>
    </div>
  );
}

export default Carousel;
