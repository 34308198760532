"use strick";
import React, { useRef } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';

// import Test from '../components/Test';

import TopBlock from '../components/TopBlock';
import DetailProduct from '../components/DetailProduct';
import CallToActionBlock from '../components/CallToActionBlock';
import MoreTiles from '../components/MoreTiles';
import Faq from '../components/Faq';

import Price from '../components/Price';
import {baseUrl} from '../config';


const GatewayPage = (ownProps) => {
  const dataPage = ownProps.route.dataItem;

  const calculatorRoot = useRef();

  return (
    <React.Fragment>
      <Helmet>
          {/* Meta tags */}
          <title>{dataPage.meta_title}</title>
          <meta name="description" content={dataPage.meta_description} />
          <meta name="keywords" content={dataPage.meta_keywords} />

          {/* Canonical */}
          <link rel="canonical" href={baseUrl + dataPage.url} />

          {/* Open Graph */}
          <html prefix="og: http://ogp.me/ns#" />
          <meta property="og:title" content={dataPage.meta_title} />
          <meta property="og:description" content={dataPage.meta_description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={baseUrl + dataPage.url} />
          <meta property="og:image" content={baseUrl + dataPage.url_image} />
      </Helmet>
      <TopBlock data={dataPage.topBlock} tiles={dataPage.moreTiles} calculatorRoot={calculatorRoot}/>
      <DetailProduct data={dataPage.tabs}/>
      <CallToActionBlock data={dataPage.callToActionBlock}/>
      <MoreTiles  data={dataPage.moreTiles}/>
      <Faq data={dataPage.faq} bgColor="white"/>
      <Price data={dataPage.price} ref={calculatorRoot}/>
    </React.Fragment>
  )
}

export default connect(
  (state, ownProps) => ({
    state: state,
    ownProps
  }),
  null
)(GatewayPage);