"use strick";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import grey from '@material-ui/core/colors/grey'
import logo from '../theme/images/logo-in-footer.svg';

import Company from './Company';

const useStyles = makeStyles(theme => ({
  root: {
    // ,
    backgroundColor: grey[900],
    color: 'rgba(255,255,255,0.75)'
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  leftBlock: {
    flexBasis: '50%',
    padding: '60px 0 60px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexBasis: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 0,
      
    },
  },
  leftBlockBottom: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  company: {
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'center',
    padding: '60px 0',
    transform: 'skew(-20deg) translateX(15%)',
    backgroundColor: fade(theme.palette.primary.main, 0.8),
    [theme.breakpoints.down('md')]: {
      flexBasis: '65%',
      transform: 'skew(-20deg) translateX(20%)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'none',
      marginLeft: -theme.spacing(3),
      marginRight: -theme.spacing(3),
      
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      
    },
    '& > section': {
      transform: 'skew(20deg)',
      [theme.breakpoints.down('sm')]: {
        transform: 'none',
      },
    }
  },
  copyright: {
    color: 'rgba(255,255,255,0.4)',
    fontSize: '1.1em',
    fontWeight: 300,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  offerLink: {
    color: 'rgba(255,255,255,0.4)',
    fontSize: '1.1em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  bottom: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      
    },
  }
}),{
  name: 'Footer'
});

const Footer = () => {
  let classes = useStyles();
  return (
    <footer className={classes.root}>
      <Container className={classes.container} maxWidth="xl">
        <div className={classes.leftBlock}>
          <img className={classes.logo} src={logo} alt='О, Удобно'/>
          <div className={classes.leftBlockBottom}>
            <div className={classes.copyright}>Все права защищены © 2022 <br/>ИП Ярцев А.А.</div>
            <Link className={classes.offerLink} to="/offer">Договор аферты</Link>
          </div>
        </div>
        <div className={classes.company}>
          <Company/>
        </div>
        <div className={classes.bottom}>
          <div className={classes.copyright}>Все права защищены © 2022 <br/>ИП Ярцев А.А.</div>
          <Link className={classes.offerLink} to="/offer">Договор аферты</Link>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
