import React from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import Container from '@material-ui/core/Container';

import SubTitle from './SubTitle';
import Carousel from './Carousel';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '100px 0',
    backgroundColor: props => props.evenTile ? 'rgba(0,0,0,0.03)' : '#fff',
    // backgroundColor: props => props.evenTile ? 'rgba(0,0,0,0.03)' : null,
    [theme.breakpoints.down('md')]: {
      padding: '70px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '25px 0',
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  
  thumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 400,
    maxWidth: 400,
    [theme.breakpoints.up('lg')]: {
      order: props => (props.evenTile) ? 3 : 2,
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: '100%',
      order: 2,
      marginBottom: theme.spacing(2)
    },
    '& > img':{
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '50%'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '70%'
      }
    }
  },
  desciption: {
    flexBasis: '60%',
    order: props => (props.evenTile) ? 2 : 3,
    padding: theme.spacing(7),
    // paddingLeft: props => (props.evenTile) ? 0 : 35,
    // paddingRight: props => (props.evenTile) ? 35 : 0,
    flexGrow: 1,
    fontSize: 20,
    color: theme.palette.text.secondary,
    lineHeight: 1.5,
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      lineHeight: 1.3,
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      order: 2,
      padding: 15,
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '& > p': {
      margin: 0,
      marginBottom: theme.spacing(1),
      
    }
  }
}),{
  name: 'Tile'
});

const Tile = (props) => {
  const classes = useStyles(props);
  const data = props.data
  
  
  return (
    <section className={classes.root} id={data.id}>
      <Container className={classes.container}>
        <SubTitle text={data.title} />
        <div className={classes.thumbnail}>
          <Carousel mediaList={data.media} type={data.media.type} hiddenAlt/>
        </div>
        
        <div className={classes.desciption}>
          <ReactMarkdown source={data.description} />
        </div>
      </Container>
    </section>
  )
}


const MoreTiles = ({data}) => {
  
  return (
    <React.Fragment>
      {data.map((item,index) => (
        <Tile data={item} key={`tile-${index}`} evenTile={index % 2}/>
      ))}
    </React.Fragment>
  )
}

export default MoreTiles;