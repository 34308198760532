import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import { 
  getPriceOneTimeCard, 
  getPriceSubscribeCard,
  getPriceOneTimeAddEquipment,
  getPriceSubscribeAddEquipment
} from '../../tools/calculatorTools'

import CalculatorOrder from './CalculatorOrder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(1,2),
    height: 68,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1200,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  title: {
    flexBasis: `15%`,
    paddingRight: theme.spacing(1),
    // flexShrink: 1,
    display: 'block',
    margin: 0,
    fontWeight: 400,
    lineHeight: '1.2em',
    fontSize: '1em',
    textTransform: 'uppercase',
  },
  child: {
    flexBasis: `20%`,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  childTitle: {
    fontSize: '0.65em',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
    fontWeight: 300,
  },
  childValue: {
    fontWeight: 600,
    fontSize: '1em',
    color: '#fff'
  },
  button: {
    color: 'rgba(255,255,255,0.84)',
    border: '1px solid rgba(255,255,255,0.25)',
    
  }
}),{
  name: 'CalculatorTotal'
})


const CalculatorTotal = (props) => {

  let classes = useStyles();

  const {
    template,
    output,
    services,
  } = props;
  
  let prices = {
    one_time: 0,
    subsribe: 0
  }

  output.workPlaces.forEach(item => {
    prices.one_time += getPriceOneTimeCard(item)
    prices.subsribe += getPriceSubscribeCard(item,services)
  })
  output.productionLines.forEach(item => {
    prices.one_time += getPriceOneTimeCard(item)
  })

  prices.one_time += getPriceOneTimeAddEquipment(output.additionalEquipments.equipments)
  prices.subsribe += getPriceSubscribeAddEquipment(output.additionalEquipments.equipments,services)

  return (
    <Slide in={template !== 'empty'} direction='up'>
      <div className={classes.root}>
        <div className={classes.child}>
          <span className={classes.childTitle}>Единоразово</span>
          <span className={classes.childValue}>{prices.one_time} руб.</span>
        </div>
        <div className={classes.child}>
          <span className={classes.childTitle}>Внедрение</span>
          <span className={classes.childValue}>0</span>
        </div>
        <div className={classes.child}>
          <span className={classes.childTitle}>Подписка</span>
          <span className={classes.childValue}>{prices.subsribe} руб.</span>
        </div>
        <CalculatorOrder />
        {/* <IconButton className={classes.button}>
          <ShoppingCartIcon color="inherit" fontSize="small"/>
        </IconButton> */}
      </div>
    </Slide>
  )
}


const mapStateToProps = (state) => {
  return {
    template: state.calculator.output.typePlace,
    output: state.calculator.output,
    services: state.calculator.data.services,
    
  };
};

export default connect(
  mapStateToProps,
  null
)(CalculatorTotal)
