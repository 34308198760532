import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';


import PlaceIcon from '@material-ui/icons/Place';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import SendIcon from '@material-ui/icons/Send';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

import { withSnackbar } from 'notistack';

// import GoogleMap from 'GoogleMap';

// import {snackbarOpen} from 'store/actions/snackbarActions.js';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    }
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  iconBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light
  },
  icon: {
    color: 'rgba(255,255,255,0.85)',
    fontSize: '1.5em',
    [theme.breakpoints.up('md')]: {
      fontSize: '2em',
    }
  },
  textBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexGrow: 1
  },
  address: {
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5em',
    }
  },
  text: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5em',
    }
  },
  actionBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  map: {
    height: 350,
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      height: 400,
    }
  }
}),{
  name: "Contacts"
});


const Contacts = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  
  const {
    company,
    enqueueSnackbar
  } = props;

  const nonBreakSpace = theme.typography.nonBreakSpace;

  const copyMailToClipboard = () => {
      navigator.clipboard.writeText(company.email)
        .then(() => enqueueSnackbar('Email скопирован в буфер обмена',{variant: 'success'}))
        .catch(() => enqueueSnackbar('Не удалось скопировать email в буфер обмена',{variant: 'error'}));
  };

  const copyPhoneToClipboard = () => {
      navigator.clipboard.writeText(company.displayPhone)
        .then(() => enqueueSnackbar('Номер телефона скопирован в буфер обмена',{variant: 'success'}))
        .catch(() => enqueueSnackbar('Не удалось скопировать номер телефона в буфер обмена',{variant: 'error'}));
  };

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.item}>
        <div className={classes.iconBlock}>
          <PlaceIcon className={classes.icon} />
        </div>
        <div className={classes.textBlock}>
          <address className={classes.address}>
            {company.address.postalCode}, {company.address.country}, {nonBreakSpace(company.address.region)}, {nonBreakSpace(company.address.city)}, {nonBreakSpace(company.address.street)}
          </address>
        </div>
      </Paper>
      <Paper elevation={2} className={classes.item}>
        <div className={classes.iconBlock}>
          <MailIcon className={classes.icon} />
        </div>
        <div className={classes.textBlock}>
          <span className={classes.text}>{company.email}</span>
        </div>
        <div className={classes.actionBlock}>
          <Tooltip title="Написать письмо" arrow>
            <IconButton href={`mailto:${company.email}`}>
              <SendIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Скопировать в буфер" arrow>
            <IconButton onClick={copyMailToClipboard}>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Paper>
      <Paper elevation={2} className={classes.item}>
        <div className={classes.iconBlock}>
          <PhoneAndroidIcon className={classes.icon} />
        </div>
        <div className={classes.textBlock}>
          <span className={classes.text}>{company.displayPhone}</span>
        </div>
        <div className={classes.actionBlock}>
          <Tooltip title="Позвонить" arrow>
            <IconButton href={`tel:${company.phone}`}>
              <PhoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Скопировать в буфер" arrow>
            <IconButton onClick={copyPhoneToClipboard}>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Paper>
      {/* <Paper elevation={2} square className={classes.map}>
        <GoogleMap />
      </Paper> */}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    company: state.data.contacts
  };
};

export default connect(
  mapStateToProps,
  null
)(withSnackbar(Contacts))