import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';

import { changeEquipmentCard, deleteEquipmentCard } from '../../../store/actions/calculatorActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: theme.spacing(1),
    
  },
  icon: {
    width: 16,
    height: 16,
    color: 'rgba(0,0,0,0.3)'
  },
  formControl: {
    // flexGrow: 1,
    padding: theme.spacing(0,1)
  },
  select: {
    fontSize: '0.9em'
  },
  price: {
    fontSize: '0.9em'
  },
  title: {
    textOverflow: ''
  },
  trashIcon: {
    marginLeft: theme.spacing(1),
    // fontSize: '0.9em'
  },
  replaceTrashIcon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1
  }
}),{
  name: 'CardEquipmentItem'
});



const CardEquipmentItem = (props) => {
  const classes = useStyles();
  
  const { 
    equipmentTypes, // список из Redux
    equipments, // список из Redux
    equipment, // элемент для отрисовки из props, поданный в родителе
    equipmentType, // тип props (gadget или equipments), поданный в родителе. Для передачи в dispatch и указания пути в редьюсере
    equipmentIndex,
    itemType, // тип props (workPlaces или productionLines), поданный в родителе. Для передачи в dispatch и указания пути в редьюсере
    itemIndex, // index в списке (workPlaces или productionLines). Для передачи в dispatch и указания пути в редьюсере
    changeEquipment, // Это самое простое, функция из коннекта,
    deleteEquipment
  } = props;

  const handleChange = event => {
    console.log('Select', event.target.value);
    const newValue = equipments.filter(item => item.title === event.target.value)[0]
    changeEquipment(itemType,itemIndex,equipmentType,equipmentIndex,newValue)
  };

  const handleDelete = () => {
    deleteEquipment(itemType,itemIndex,equipmentType,equipmentIndex)
  };

  // console.log('equipmentTypes',equipmentTypes);

  const filterEquipmentsTypes = (item,equipment) => (equipment.type.indexOf('gadget') + 1) ? (item.type.indexOf('gadget') + 1) : (item.type === equipment.type)

  const Icon = equipmentTypes[equipment.type].icon

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}/>
      
      <FormControl className={classes.formControl}>
        <Select 
          classes={{
            root: classes.select
          }}
          value={equipment.title} 
          onChange={handleChange} 
          className={classes.selectEmpty}
          disableUnderline
          
        >
          {equipments.filter(item => filterEquipmentsTypes(item,equipment)).map((item,index) => (
            <MenuItem
              value={item.title}
              key={`item-${index}`}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={classes.flexGrow}/>
      <span className={classes.price}> {equipment.price} </span>
      
      {(equipmentTypes[equipment.type].can_delete) ? (
        <Tooltip title={`Удалить ${equipment.title}`}>
          <IconButton aria-label="delete" size="small" className={classes.trashIcon} onClick={handleDelete}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ) : (
        <span className={classes.replaceTrashIcon}/>
      )}
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    equipmentTypes: state.calculator.data.equipmentTypes,
    equipments: state.calculator.data.equipments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeEquipment: (itemType,itemIndex,equipmentType,indexEquipment,newValue) => {dispatch(changeEquipmentCard({itemType,itemIndex,equipmentType,indexEquipment,newValue}))},
    deleteEquipment: (itemType,itemIndex,equipmentType,indexEquipment) => {dispatch(deleteEquipmentCard({itemType,itemIndex,equipmentType,indexEquipment}))}
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardEquipmentItem)