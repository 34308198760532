import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: '100%',
    fontWeight: 900,
    fontSize: 50,
    textTransform: 'uppercase',
    color: props => props.color === 'white' ? 'rgba(255,255,255,0.85)' : theme.palette.text.primary,
    marginBottom: 50,
    marginTop: props => props.homePage ? 0 : theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('md')]: {
      // fontSize: 35,
      marginBottom: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      // marginBottom: 15
    }
  }
}),{
  name: 'MainTitle'
});

const MainTitle = (props) => {
  let classes = useStyles(props);
  return (
    <h1 className={classes.root}>
      {props.text}
    </h1>
  )
}

export default MainTitle;