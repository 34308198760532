import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';

import { changeNameItem } from '../../../store/actions/calculatorActions';

const useStyles = makeStyles(theme => ({
  root: {

  },
  title: {
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: '0.95em'
  },
  submit: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.dark,
    }
  }
}),{
  name: 'CardPrice'
});

const CardMenuEditItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    value,
    itemType,
    itemIndex,
    changeName,
    handleClose
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    handleClose()
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const inputRef = useRef()

  const handleSave = () => {
    changeName(itemType, itemIndex, inputRef.current.value);
    setOpen(false);

  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary="Переименовать" />
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={fullWidth}
        maxWidth='sm'
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Название места расчета гостей</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            inputRef={inputRef}
            margin="dense"
            id="name"
            label="Название"
            fullWidth
            defaultValue={value}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Отмена
          </Button>
          <Button className={classes.submit} onClick={handleSave}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => {
  return {
    changeName: (itemType,itemIndex,newName) => {dispatch(changeNameItem({itemType,itemIndex,newName}))}
  }
};
export default connect(
  null,
  mapDispatchToProps
)(CardMenuEditItem)