import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: '50%',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    [theme.breakpoints.only('xs')]: {
      alignSelf: 'flex-end'
    }
  },
  item: {
    display: 'inline-block',
    marginTop: 8,
    paddingBottom: '5px',
    color: '#333',
    textDecoration: 'none',
    fontSize: '1.2em',
    fontWeight: 500,
    borderBottom: '3px solid transparent',
    marginLeft: 100,
    textTransform: 'uppercase',
    '&:hover, &:focus': {
      borderBottomColor: theme.palette.primary.light,
      color: '#000'
    },
    '&:first-child': {
      marginLeft: 0
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 50,
      fontSize: '1.15em'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'inline-block',
      marginLeft: 0,
      fontSize: '1em',
      borderBottom: 'none',
      marginTop: 0,
      marginBottom: 5,
      paddingBottom: 0,
      paddingRight: 15,
      '&:after': {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'rotate(-45deg) translateY(-50%)',
        display: 'block',
        content: '""',
        width: 5,
        height: 5,
        borderLeft: '1px solid #aaa',
        borderTop: '1px solid #aaa'
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      fontSize: '1.15em'
    },
  },
  itemActive: {
    borderBottomColor: theme.palette.primary.main,
  }
}),{
  name: 'mainMenu'
});

const MainMenu = () => {
  let classes = useStyles();
  return (
    <nav className={classes.root}>
      <NavLink activeClassName={classes.itemActive} className={classes.item} exact={true} to="/">Удаленная работа</NavLink>
      <NavLink activeClassName={classes.itemActive} className={classes.item} exact={true} to="/horeca">Ресторанам и кафе</NavLink>
      {/* <NavLink activeClassName={classes.itemActive} className={classes.item} to="/retail">Розничной торговле</NavLink> */}
      <NavLink activeClassName={classes.itemActive} className={classes.item} to="/contacts">Поддержка</NavLink>
      <NavLink activeClassName={classes.itemActive} className={classes.item} to="/offer">Оферта</NavLink>
    </nav>
  )
}

export default MainMenu;