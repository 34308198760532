import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CalculatorItemCard from './CalculatorItemCard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-1)
  },
  child: {
    flexBasis: `100%`,
    maxWidth: '100%',
    padding: theme.spacing(1)
  }
}),{
  name: 'ProductionLinesList'
})


const ProductionLinesList = (props) => {
  let classes = useStyles();
  
  
  const {
    productionLines
  } = props;
  
  return (
    <div className={classes.root}>
      {productionLines.map((item,index) => (
        <div className={classes.child} key={`productionLine-${index}-${item.name}`}>
          <CalculatorItemCard item={item} itemIndex={index} type="productionLines"/>
        </div>
      ))}
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    productionLines: state.calculator.output.productionLines
  };
};

export default connect(
  mapStateToProps,
  null
)(ProductionLinesList)
