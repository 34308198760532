"use strick";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import { connect } from 'react-redux';

import HeaderContacts from './HeaderContacts';
import Logo from './Logo';
import MainMenu from './MainMenu';
import grey from '@material-ui/core/colors/grey'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 2,
    // paddingTop: 50,
    fontFamily: theme.typography.fontFamily
  },
  top: {
    // position: 'fixed',
    position: 'relative',
    top: 0,
    width: '100%',
    backgroundColor: grey[100],
    boxShadow: theme.shadows[2],
    zIndex: 1050
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  bottom: {
    backgroundColor: grey[100],
    padding: '35px 0 25px 0',
    [theme.breakpoints.down('md')]: {
      
    }
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.only('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
}),{
  name: 'Header'
});

const Header = () => {
  let classes = useStyles();
  return (
    <header className={classes.root} id='#top'>
      <div className={classes.top}>
        <Container className={classes.topContainer} maxWidth='xl'>
          <HeaderContacts/>
        </Container>
      </div>
      <div className={classes.bottom}>
        <Container className={classes.bottomContainer} maxWidth='xl'>
          <Logo />
          <MainMenu />
        </Container>
      </div>
    </header>
  )
}

export default Header;