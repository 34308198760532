"use strick";
import React, { useEffect } from "react";
import BaseLayout from './BaseLayout';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';



const App = ({route,pathname}) => {
  
  useEffect(() => {
    window && window.scrollTo({
      top: 0
    });
  }, [pathname]);

  return (
    <BaseLayout>
      {renderRoutes(route.routes)}
    </BaseLayout>
  )
}

export default connect(
  (state, ownProps) => ({
    state: state,
    pathname: ownProps.location.pathname
  }),
  null
)(App);
