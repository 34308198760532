import TabletIcon from '@material-ui/icons/Tablet';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MoneyIcon from '@material-ui/icons/Money';
import ScannerIcon from '@material-ui/icons/Scanner';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

export default {
  gadget_pos_interface: {
    alias: 'gadget_pos_interface',
    connect_in_slot: true,
    icon: TabletIcon,
    sort: 100,
    can_delete: false,
  },
  gadget_hw_rpoxy: {
    alias: 'gadget_hw_rpoxy',
    connect_in_slot: true,
    icon: AccountTreeIcon,
    sort: 110,
    can_delete: false
  },
  gadget_pos_interface_and_hw_proxy: {
    alias: 'gadget_pos_interface_and_hw_proxy',
    connect_in_slot: true,
    icon: DesktopWindowsIcon,
    sort: 100,
    can_delete: false
  },
  ksa: {
    alias: 'ksa',
    connect_in_slot: false,
    icon: MoneyIcon,
    sort: 200,
    can_delete: true
    
  },
  magnet_scanners: {
    alias: 'magnet_scanners',
    connect_in_slot: false,
    icon: ScannerIcon,
    sort: 300,
    can_delete: true
  },
  task_transfers: {
    alias: 'task_transfers',
    connect_in_slot: false,
    icon: LowPriorityIcon,
    sort: 400,
    can_delete: true
  },
  mobile_workplace: {
    alias: 'mobile_workplace',
    connect_in_slot: false,
    icon: TabletIcon,
    sort: 100,
    can_delete: true
  },
  
}