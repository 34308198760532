import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import EquipmentsList from "./EquipmentsList";
import FeaturesList from './FeaturesList';
import AdditionalEquipmentsListPrice from './AdditionalEquipmentsListPrice';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // margin: theme.spacing(-1)
    padding: theme.spacing(2)
  },
  child: {
    flexBasis: `100%`,
    padding: theme.spacing(1)
  }
}),{
  name: 'AdditionalEquipmentsList'
})


const AdditionalEquipmentsList = (props) => {
  let classes = useStyles();
  
  
  const {
    features,
    equipments
  } = props;
  
  return (
    <Paper className={classes.root}>
      <FeaturesList featuresList={features} />
      {/* <Divider /> */}
      <EquipmentsList equipmentsList={equipments}/>
      <Divider />
      <AdditionalEquipmentsListPrice equipments={equipments} />
    </Paper>
  )
}


const mapStateToProps = (state) => {
  return {
    features: state.calculator.output.additionalEquipments.features,
    equipments: state.calculator.output.additionalEquipments.equipments,
    price: state.calculator.output.additionalEquipments.price
  };
};

export default connect(
  mapStateToProps,
  null
)(AdditionalEquipmentsList)






