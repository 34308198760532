import React from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import company from '../data/contacts';

// import IconButton from '@material-ui/core/IconButton';
// import SvgIcon from '@material-ui/core/SvgIcon';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: 'rgba(255,255,255,0.84)',
    padding: theme.spacing(0,2)
    // [theme.breakpoints.only('xs')]: {
    //   flexBasis: 60,
    //   marginBottom: 20
    // }
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
    marginTop: 0,    
    marginBottom: 15,
    textAlign: 'right'
  },
  address: {
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 300,
    marginBottom: 25,
  },
  phone: {
    fontSize: 22,
    color: 'rgba(255,255,255,0.84)',
    textDecoration: 'none',
    marginBottom: 25,
  },
  email: {
    fontSize: 16,
    color: 'rgba(255,255,255,0.84)',
    textDecoration: 'none'
  },
  social: {
    display: 'flex',
    marginTop: 35,
    
  },
  icon: {
    fontSize: '2em'
  },
  socialLink: {
    display: 'flex',
    padding: theme.spacing(2),
    // borderRadius: 10,
    '& svg': {
      fontSize: '3em',
      '& path': {
        color: 'rgba(255,255,255,0.4)',
      }
    },
    '&:hover svg path': {
      // color: 'currentcolor'
      color: 'rgba(255,255,255,0.85)',
    }
  }
}),{
  name: 'Company'
});


const Company = () => {
  let classes = useStyles();
  return (
    <section className={classes.root}>
      <h4 className={classes.name}>{company.name}</h4>
      <address className={classes.address}>
        {company.address.postalCode}, {company.address.country}, {company.address.region}, <br/>{company.address.city}, {company.address.street}
      </address>
      <a className={classes.phone} href={`tel:${company.phone}`}>{company.displayPhone}</a>
      <a className={classes.email} href={`mailto:${company.email}`}>{company.email}</a>
      
        {(company.socials.length > 0) && (
          <div className={classes.social}>
            {company.socials.map((social,index) => (
              <a 
                className={classes.socialLink}
                href={social.link}
                title={social.name}
                size="large"
                key={`social-${index}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className={classes.icon} color={social.mainColor} icon={social.icon}/>
              </a>
            ))}
          </div>
        )}
      
    </section>
  )
}

export default Company;
