import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Typography from '@material-ui/core/Typography';


import Carousel from './Carousel';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  },
  title: {
    flexBasis: '100%',
    fontWeight: 900,
    fontSize: 18,
    color: '#333333',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    }
  },
  mediaBlock: {
    flexBasis: 400,
    paddingTop: 10,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      maxWidth: '100%'
    }
  },
  image: {
    backgroundImage: item => `url('${item.media[0].path}')`,
    // display: 'block',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat no-repeat',
    width: 400,
    height: 225,
    // cursor: 'pointer'
  },
  descriptionBlock: {
    flexBasis: '50%',
    flexGrow: 1,
    padding: theme.spacing(0,2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      padding: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0,0,0,3),
      marginTop: theme.spacing(1),
    }
  },
  
  decription: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontSize: 15,
    lineHeight: 1.2,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 19,
    },
    '& > p': {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
    '& > ul': {
      marginTop: 0,
      paddingLeft: theme.spacing(3),
    }
  }
}),{
  name: 'DetailProductItem'
});



const DetailProductItem = ({ children, value, index, item, ...other }) => {
  let classes = useStyles(item);
  return (
    <Typography component="div" role="tabpanel" className={classes.root} hidden={value !== index} {...other}>
      {value === index && (
        <React.Fragment>
          <h3 className={classes.title}>{item.title}</h3>
          <div className={classes.mediaBlock}>
            <Carousel mediaList={item.media} />
          </div>
          <div className={classes.descriptionBlock}>
            <div className={classes.decription}>
              <ReactMarkdown source={item.description}/>
            </div>
          </div>
        </React.Fragment>
      )}
    </Typography>
  );
}

DetailProductItem.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  item: PropTypes.object.isRequired
};

export default DetailProductItem;