import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// import { applyTemplate, changeValue } from '../../store/actions/calculatorActions'
import { applyTemplate } from '../../store/actions/calculatorActions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3,0,0,0),
  },
  textField: {
    marginBottom: theme.spacing(2),
  }
}),{
  name: 'DepartmentForm'
});

const DepartmentForm = (props) => {
  let classes = useStyles();
  
  const {
    templates,
    currentValues,
    handleTemplate,
    // handleCountWorkPlaces
  } = props;

  
  return (
    <form className={classes.root}>
      <TextField
        className={classes.textField}
        id="template"
        label="Выберите шаблон предложения"
        helperText="Здесь представлены шаблоны, который помогут вам начать"
        select
        value={currentValues.typePlace}
        onChange={handleTemplate}
        fullWidth
      >
        {templates.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* <TextField
          id="countWorkPlaces"
          type="number"
          label="Количество мест расчета гостей"
          helperText="Укажите сколько планируется мест принятия денежных средств"
          value={currentValues.countWorkPlaces}
          fullWidth
          onChange={handleCountWorkPlaces('countWorkPlaces')}
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
    </form>
  )
}


const mapStateToProps = (state) => {
  return {
    currentValues: state.calculator.output,
    templates: state.calculator.data.templates.filter(template => !template.notDisplay).map((template) => {
      return {
        value:template.alias,
        label:template.name
      }
    })
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleTemplate: event => dispatch(applyTemplate(event.target.value)),
    // handleCountWorkPlaces: name => event => dispatch(changeValue({name, value: event.target.value}))
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentForm)
