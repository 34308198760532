export function init() {
  return {
      type: '@@calculator/init',
  };
}

export function clearData() {
  return {
      type: '@@calculator/clearData'      
  };
}

export function changeValue(payload) {
  console.log('payload',payload);
  
  return {
      type: '@@calculator/CHANGE_VALUE',
      payload: payload
  };
}
export function toggleFeature(index) {
  return {
    type: '@@calculator/TOGGLE_FEATURE',
    payload: index
  }
}

export function toggleFeatureCard(dict) {
  return {
    type: '@@calculator/TOGGLE_CARD_FEATURE',
    payload: dict
  }
}

export function changeEquipmentCard(dict) {
  return {
    type: '@@calculator/CHANGE_EQUIPMENT_CARD',
    payload: dict
  }
}

export function deleteEquipmentCard(dict) {
  return {
    type: '@@calculator/DELETE_EQUIPMENT_CARD',
    payload: dict
  }
}

export function deleteItem(dict) {
  return {
    type: '@@calculator/DELETE_CARD',
    payload: dict
  }
}

export function cloneItem(dict) {
  return {
    type: '@@calculator/CLONE_CARD',
    payload: dict
  }
}

export function changeNameItem(dict) {
  return {
    type: '@@calculator/CHANGE_NAME_CARD',
    payload: dict
  }
}

export function addCard(dict) {
  return {
    type: '@@calculator/ADD_CARD',
    payload: dict
  }
}

export function applyTemplate(templateName) {
  return {
      type: '@@calculator/applyTemplate',
      payload: templateName
  };
}

export function toggleFeatureAdditionalEquipments(index) {
  return {
    type: '@@calculator/TOGGLE_FEATURE_ADD_EQUIPMENTS',
    payload: index
  }
}

export function changeEquipmentAdditionalEquipments(dict) {
  return {
    type: '@@calculator/CHANGE_EQUIPMENT_ADD_EQUIPMENTS',
    payload: dict
  }
}

export function deleteEquipmentAdditionalEquipments(index) {
  return {
    type: '@@calculator/DELETE_EQUIPMENT_ADD_EQUIPMENTS',
    payload: index
  }
}

export function setCountEquipmentAdditionalEquipments(dict) {
  return {
    type: '@@calculator/SET_COUNT_EQUIPMENT_ADD_EQUIPMENTS',
    payload: dict
  }
}