import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  item: {
    // marginBottom: theme.spacing(2)
  },
  heading: {
    fontWeight: 500
  }
}));

const FaqList = ({data}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      {data.map( (item, index) => (
        <Accordion 
          className={classes.item} 
          expanded={expanded === `question${index}`} 
          onChange={handleChange(`question${index}`)}
          key={`question${index}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`question${index}-answer`}
            id={`question${index}`}
          >
            <Typography className={classes.heading}>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">
              <ReactMarkdown source={item.answer} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      )

      )}
      
    </React.Fragment>
  );
}


export default FaqList;