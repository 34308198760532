import React from "react";
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import CardMenuEditItem from './CardMenuEditItem';

import { deleteItem, cloneItem } from '../../../store/actions/calculatorActions';

import { withSnackbar } from 'notistack';

const CardMenu = (props) => {
  
  const {
    itemName,
    itemType,
    itemIndex,
    deleteCard,
    cloneCard,
    enqueueSnackbar
  } = props;
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClone = () => {
    cloneCard(itemType,itemIndex);
    enqueueSnackbar('Создана копия')
    handleClose()
  };

  const handleDelete = () => {
    deleteCard(itemType,itemIndex);
    enqueueSnackbar('Удалено')
    handleClose()
  };

  

  
  return (
    <React.Fragment>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`setting-work-place-${itemIndex}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>Рабочее место</MenuItem>
        <Divider />
        <CardMenuEditItem value={itemName} itemType={itemType} itemIndex={itemIndex} handleClose={handleClose}/>
        
        <MenuItem onClick={handleClone}>
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText primary="Клонировать" />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          <ListItemText primary="Удалить" />
          </MenuItem>
      </Menu>
    </React.Fragment>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
    deleteCard: (itemType, itemIndex) => dispatch(deleteItem({itemType, itemIndex})),
    cloneCard: (itemType, itemIndex) => dispatch(cloneItem({itemType, itemIndex}))
  }
};
export default connect(
  null,
  mapDispatchToProps
)(withSnackbar(CardMenu))