import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import contacts from '../../data/contacts';
import products from '../../data/products';

import calculator from './calculatorReducers';

// Шаблон Reducer для статиеского контента
const staticReducer = content => {
  return (state = content, action) => {
    switch (action.type) {
      default:
        return state
    }
  }
}

export default combineReducers({
  routing: routerReducer,
  data: combineReducers({
    contacts: staticReducer(contacts),
    products: staticReducer(products)
  }),
  calculator
})

