import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import SubTitle from './SubTitle';
import FaqList from './FaqList';

import contacts from '../data/contacts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '100px 0',
    backgroundColor: props => props.bgColor === 'white' ? '#fff' : 'rgba(0,0,0,0.03)',
    [theme.breakpoints.down('md')]: {
      padding: '70px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '25px 0',
    }
  },
  callToAction: {
    marginTop: 50,
    textAlign: "center"
  },
  callToActionQuestion: {
    fontWeight: 300,
    color: theme.palette.text.secondary,
    marginBottom: 15
  },
  callToActionAnswer: {
    fontWeight: 700,
    color: theme.palette.text.primary
  }

}),{
  name: 'Faq'
});


const Faq = (props) => {
  const theme = useTheme()
  const classes = useStyles(props);

  const data = props.data

  return (
    <section className={classes.root}>
      <Container maxWidth="md">
        <SubTitle text={data.title} />
        <FaqList data={data.questions}/>
        <div className={classes.callToAction}>
          <Typography className={classes.callToActionQuestion} component='h3' variant='h5'>{data.callToAction.question}</Typography>
          <Typography className={classes.callToActionAnswer} component='h4' variant='h4'>
            {data.callToAction.answer}
            <br/>
            {theme.typography.nonBreakSpace(contacts.displayPhone)}
          </Typography>
        </div>
      </Container>
      
    </section>
    
  )
}

export default Faq;