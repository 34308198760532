export default [
  {
    alias:"pos_interface",
    title:"Рабочее Место Кассира",
    term: 8,
    price:15.00,
    uom:"в месяц",
  },
  {
    alias:"hw_proxy", 
    title:"Сервер Работы Оборудования",
    term: 8,
    price:35.00,
    uom:"в месяц",
  }
]