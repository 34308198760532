import React from "react";
import imageWebp from '../../theme/images/horeca.webp';

// import backgroundImageHoreca from '../../theme/images/horeca.webp';

import contacts from '../contacts';

const description = (
    <React.Fragment>
        <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях. При создании генератора мы использовали небезизвестный универсальный код речей.</p>
        <p>Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия.</p>
    </React.Fragment>
)

export default {
  meta_title: 'Система автоматизации для розничных магазинов',
  meta_description: '',
  meta_keywords: '',
  topBlock: {
      title: 'Система автоматизации для розничных магазинов',
      callToAction: {
          title: 'От 50 руб.',
          buttonDemo: {
              title: 'Бесплатный демо доступ',
              action: '#demo'
          },
          buttonIntegration: {
              title: 'Бесплатная интеграция',
              action: '#integration'
          }
      }
  },
  tabs: {
      title: '',
      items: []
  },
  callToActionBlock: {
    title: 'Есть вопросы?',
    button: {
        title: 'Получите бесплатную консультацию',
        action: ''
    }
  },
  moreTiles: [
    {
      title: 'Поддержка кассового аппарата Титан',
      desription: description,
      media: [
        {
          thumbnail: imageWebp,
          path: imageWebp,
          label: 'Alt text картинки',
          type: 'image'
        }
      ]
    },
    {
      title: 'Работа с принтерами ESCPOS',
      desription: description,
      media: [
        {
          thumbnail: imageWebp,
          path: imageWebp,
          label: 'Alt text картинки',
          type: 'image'
        }
      ]
    },
    {
      title: 'Интеграция с 1с',
      desription: description,
      media: [
        {
          thumbnail: imageWebp,
          path: imageWebp,
          label: 'Alt text картинки',
          type: 'image'
        }
      ]
    }
  ], //end moreTiles
  faq: {
    title: 'Часто задаваемые вопросы',
    callToAction: {
      question: 'Не нашли ответ на интересующий Вас вопрос?',
      answer: `Звоните ${contacts.displayPhone}`
    },
    questions: [
      {
        question: 'Вопрос 1',
        answer: 'Ответ 1'
      },
      {
        question: 'Вопрос 2',
        answer: 'Ответ 2'
      },
      {
        question: 'Вопрос 3',
        answer: 'Ответ 3'
      },
    ]
  }
}