import React from "react";
// import { Link } from 'react-router-dom';
import { makeStyles,useTheme } from '@material-ui/core/styles';


import SwipeableViews from 'react-swipeable-views';
import Container from '@material-ui/core/Container';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ReactMarkdown from 'react-markdown';

import SubTitle from './SubTitle';
import DetailProductItem from './DetailProductItem';



const useStyles = makeStyles(theme => ({
  root: {
    padding: '80px 0',
    [theme.breakpoints.down('md')]: {
      padding: '70px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 0',
    },
  },
  description: {
    fontSize: '1.2em',
    marginBottom: theme.spacing(4),
    
  }
}),{
  name: 'DetailProduct'
});

function a11yProps(index) {
  return {
    key: `full-width-tab-${index}`,
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DetailProduct = ({data}) => {
    let classes = useStyles();
    let theme = useTheme();
    let [value, setValue] = React.useState(0);
    let handleChange = (event, newValue) => {setValue(newValue)};
    let handleChangeIndex = index => {setValue(index)};

    // let data = detailProductData;

    // console.log('detailProductData',data);
    

    
    return (
      <section className={classes.root}>
        <Container>
          <SubTitle text={data.title} />
          {data.description && (
            <div className={classes.description}>
              <ReactMarkdown source={data.description} />
            </div>
          )}
          
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" aria-label="simple tabs example" >
            {data.items.map((item, index) => (
              <Tab label={item.tabName} {...a11yProps(index)} />
            ))}
          </Tabs>
          <SwipeableViews axis='x' index={value} onChangeIndex={handleChangeIndex}>
            {data.items.map((item, index) => (
              <DetailProductItem 
                value={value} 
                index={index} 
                dir={theme.direction} 
                key={`full-width-tabpanel-${index}`}
                id={`full-width-tabpanel-${index}`} 
                aria-labelledby={`full-width-tab-${index}`}
                item={item}/>
            ))}
          </SwipeableViews>
        </Container>
      </section>
    
  )
}



export default DetailProduct;