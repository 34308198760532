"use strick";
import React from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import MainTitle from '../components/MainTitle';
import Contacts from '../components/Contacts';
import ContactsForm from '../components/ContactsForm';

import page from '../data/pages/contacts';
import {baseUrl} from '../config';
const ContactsPage = () => {
  
  return (
    <React.Fragment>
        <Helmet>
          {/* Meta tags */}
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_description} />
          <meta name="keywords" content={page.meta_keywords} />

          {/* Canonical */}
          <link rel="canonical" href={baseUrl + page.url} />

          {/* Open Graph */}
          <html prefix="og: http://ogp.me/ns#" />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:description" content={page.meta_description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={baseUrl + page.url} />
          <meta property="og:image" content={baseUrl + page.url_image} />
      </Helmet>
      <Container>
        <MainTitle text="Контактная информация"/>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Contacts />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactsForm />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
    
  )
}

export default connect(
  (state, ownProps) => ({
    state: state,
    ownProps
  }),
  null
)(ContactsPage);