import React from "react";
// import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import EquipmentsListItem from './EquipmentsListItem';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  child: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1)
  },

}),{
  name: 'EquipmentsList'
})


const EquipmentsList = (props) => {
  let classes = useStyles();
  
  
  const {
    equipmentsList
  } = props;
  
  return (
    <div className={classes.root}>
      {equipmentsList.length ? equipmentsList.map((item,index) => (
        <EquipmentsListItem equipment={item} equipmentIndex={index} key={`additionalEquipments-${index}`} />
      )) : (
        <div>Нет оборудования</div>
      )}
    </div>
  )
}


export default EquipmentsList;






