import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CardHelper from './CardHelper';


import { toggleFeatureCard } from '../../../store/actions/calculatorActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2,0),
    fontSize: '0.9em'
  },
  item: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    }
  },
  formGroup: {
    flexGrow: 1,
  },
  switch: {
    marginRight: theme.spacing(1),
  },
  label: {
    lineHeight: 1.1,
    fontSize: '1.05em'
  }
}),{
  name: 'CardFeatures'
});



const CardFeatures = (props) => {
  const classes = useStyles();
  
  const { 
    features,
    itemIndex,
    itemType,
    toggleFeature
  } = props;

  const handleChange = featureIndex => () => {
    toggleFeature(itemType,itemIndex,featureIndex)
  }

  return (
    <div className={classes.root}>
      {features.map((line,featureIndex) => (
        <div className={classes.item} key={`item-deature-${featureIndex}`}>
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              classes={{
                label: classes.label
              }}
              control={
                <Switch
                  classes={{
                    root: classes.switch
                  }}
                  checked={line.check}
                  size="small"
                  onChange={handleChange(featureIndex)}
                  value={line.alias}
                  color="primary"
                />
              }
              label={line.label}
            />
          </FormGroup>
          <CardHelper title={line.label} text={line.helper}/>
        </div>
      ))}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFeature: (itemType,itemIndex,indexFeature) => {dispatch(toggleFeatureCard({itemType,itemIndex,indexFeature}))}
  }
};
export default connect(
  null,
  mapDispatchToProps
)(CardFeatures)