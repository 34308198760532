import React, { forwardRef } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';


import SubTitle from '../SubTitle';
import CalculatorBody from '../Calculator/CalculatorBody';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '100px 0',
    backgroundColor: 'rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: {
      padding: '70px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '25px 0',
    }
  }
}),{
  name: 'Calculator'
});



const Calculator = forwardRef((props, calculatorRoot) => {
  let classes = useStyles();
  
  return (
    <section className={classes.root} ref={calculatorRoot}>
      <Container>
        <SubTitle text="Калькулятор" />
        <CalculatorBody calculatorRoot={calculatorRoot}/>
      </Container>
    </section>
    
  )
})

export default Calculator