import React from 'react';

import default_og_image from '../../theme/images/default-og-image.jpg';
export default {
  title: 'Договор оферты',
  meta_title: 'Договор оферты «О, удобно»',
  meta_description: '',
  meta_keywords: 'Договор оферты',
  url: '/offer',
  url_image: default_og_image,

  body: (
    <React.Fragment>
      <p>Индивидуальный предприниматель Ярцев Александр Андреевич, действующий на основании свидетельства, именуемый в дальнейшем «Подрядчик» с одной стороны,  и заказчик услуг аренды программного обеспечения, безоговорочно акцептовавший настоящее публичное предложение (Публичный договор возмездного оказания услуг аренды Программного Обеспечения), далее именуемый Заказчик, пришли к соглашению и заключили настоящий договор о нижеследующем</p>
      <ol className='uppercase'>
        <li>ПРЕДМЕТ ДОГОВОРА
          <ol>
            <li>
              Подрядчик принимает на себя обязательства оказать услугу по предоставлению доступа Заказчика к Программному Обеспечению далее ПО, в соответствии с п 1.2 настоящего договора, а Заказчик обязуется принять услуги, оказанные Подрядчиком, и оплатить их в порядке и на условиях, определенных настоящим договором. Подрядчик самостоятельно определяет способы оказания услуг. Подрядчик вправе привлечь к исполнению своих обязательств субподрядчиков
            </li>
            <li>
              Перечень услуг, являющихся предметом настоящего договора, период (срок) их предоставления, а также другие условия оказания услуг, указываются в счете-фактуре (или Акте сдачи-приемки выполненных работ).
            </li>
          </ol>
        </li>
        <li>СРОКИ И СПОСОБЫ ОКАЗАНИЯ УСЛУГ
          <ol>
            <li>Программное обеспечение указанное в п 1.1 разворачивается (устанавливается) на оборудовании Заказчика следующим образом:</li>
              <ol>
                <li>Силами и средствами Заказчика</li>
                <li>Оборудование (персональные компьютеры, комплектующие, чековые принтеры и прочее), приобретаются Заказчиком самостоятельно на основании спецификации являющейся неотъемлемой частью данного договора.</li>
                <li>Заказчик в праве привлечь для развертывания (установки) ПО и (или) необходимого оборудования Подрядчика за дополнительную оплату, которая будет отражена в счете-фактуре (или Акте сдачи-приемки выполненных работ).</li>
              </ol>
              <li>Качество оказываемой Подрядчиком услуги должно соответствовать требованиям, обычно предъявляемым к работам соответствующего рода.</li>
          </ol>
        </li>
        <li>ПРАВА И ОБЯЗАННОСТИ СТОРОН
          <ol>
            <li>По настоящему договору Подрядчик обязан: </li>
              <ol>
                <li>оказать Заказчику услуги (после исполнения п 5.1.) в объеме и в сроки, согласованные сторонами настоящего договора, указанные в счете-фактуре; </li>
                <li>обеспечить размещение на информационно-технических ресурсах Подрядчика ПО, указанное в п. 1.1, для эксплуатации его Заказчиком</li>
                <li>консультировать в рабочее время (с 9-00 до 18-00, за исключением выходных дней и государственных праздников) Заказчика по электронной почте по вопросам, возникающим у Заказчика в связи с исполнением настоящего договора; </li>
                <li>обеспечить конфиденциальность информации, предоставленной Заказчиком, за исключением случаев, когда предоставление доступа к такой информации для третьих лиц обусловлено условиями настоящего договора, либо является обязательным в силу предписаний законодательства.</li>
              </ol>
            <li>Подрядчик имеет право:</li>
            <ol>
              <li>временно приостановить предоставление услуг Заказчику (до устранения причин, послуживших основанием к приостановлению) в случае:

                <p> - если Заказчик частично либо полностью не выполняет условия настоящего Договора;</p>
                <p> - если Заказчик не исполнил обязательства по оплате услуг, в порядке, установленном настоящим договором.</p>
               Поскольку на время приостановления предоставления услуг Заказчику в соответствии с настоящим пунктом настоящего договора Подрядчик обеспечивает сохранность информации (данных), размещенных Заказчиком на информационно-технических ресурсах Подрядчика, а оказание услуг приостанавливается по причине нарушения условий договора Заказчиком, стороны пришли к соглашению о том, что Заказчик на время приостановления предоставления услуг не освобождается от обязанности оплачивать услуги Подрядчика по обеспечению сохранности информации (данных), размещенных Заказчиком на информационно-технических ресурсах Подрядчика. При этом размер оплаты услуги Подрядчика по обеспечению сохранности информации (данных) Заказчика равен стоимости оказываемых Заказчику в рамках настоящего договора услуг за период времени, в течение которого предоставление услуг было приостановлено. </li>
              <li>расторгнуть настоящий договор в одностороннем порядке (отказаться от исполнения договора) и удалить информацию, предоставленную Заказчиком и размещенную на информационно-технических ресурсах Подрядчика, в случае: 
                <p> - если Заказчик не устранил допущенные им нарушения настоящего договора в течение 10 (десяти) дней с момента получения претензии Подрядчика об их устранении; </p>
                <p> - если в течение одного месяца с момента окончания ранее оплаченного периода предоставления Услуг Заказчик не исполнит обязательства по оплате услуг за следующий период; </p>
                <p> - если Заказчик частично или полностью не выполняет (ненадлежащим образом выполняет) условия (условие) договора. </p>

              </li>
              <li>изменять технические характеристики и параметры программно-технических средств, задействованных в оказании Услуг, в случае, если такие изменения направлены на поддержку работоспособности программно-технических средств либо на улучшение их функционирования; </li>
              <li>временно (на срок до двух суток) приостанавливать оказание Услуг, если это необходимо для совершения действий, указанных в п. 3.2.3. настоящего договора; </li>
              <li>хранить информацию обо всех подключениях Заказчика к информационно-техническим ресурсам Подрядчика, если такая информация была получена в ходе оказания услуг.</li>
            </ol>
            <li>По настоящему договору Заказчик обязан:</li>
            <ol>
              <li>надлежащим образом исполнять условия настоящего договора, своевременно и в полном объеме оплачивать Подрядчику оказываемые Услуги</li>
              <li>при оформлении заказа на услуги предоставлять Подрядчику полную, правдивую и точную информацию;</li>
              <li>обеспечить конфиденциальность полученной от Подрядчика служебной информации (имена и пароли доступа, номера мобильных телефонов специалистов Подрядчика и т.п.); </li>
              <li>предоставлять Подрядчику информацию (данные), не противоречащую законодательству Республики Беларусь, не нарушающую права и законные интересы третьих лиц.</li>
              <li>ести себя вежливо по отношению к Подрядчику (его представителю). </li>
            </ol>
            <li>Заказчик имеет право:</li>
            <ol>
              <li>на надлежащее оказание Подрядчиком услуг, предоставляемых по настоящему договору;</li>
              <li>получать от Подрядчика в рабочее время, с 9-00 до 18-00 (за исключением выходных дней и государственных праздников), консультации по электронной почте по вопросам, возникающим в связи с исполнением настоящего договора; </li>
              <li>расторгнуть настоящий договор в случае: 
                <p> - существенного нарушения Подрядчиком принятых на себя по настоящему договору обязательств; </p>
                <p> - несогласия с вносимыми Подрядчиком изменениями и (или) дополнениями в настоящий договор. </p>
              </li>
            </ol>
          </ol>
        </li>
        <li>ПОРЯДОК РАСЧЕТОВ
          <ol>
            <li>Стоимость услуг, оказываемых в рамках настоящего договора, определяется в соответствии с прейскурантами (являющимися неотъемлемой частью настоящего договора), действующими непосредственно в момент выставления требования (счета-фактуры или Акте сдачи-приемки выполненных работ) об оплате заказанных услуг и размещенными на сайте Подрядчика по адресу: http://oodobno.by</li>
            <li>Заказчик производит предварительную оплату заказанных услуг на основании выставленной счета-фактуры (или Акте сдачи-приемки выполненных работ) не позднее 10 (десяти) дней с момента выставления.</li>
            <li>Заказчики - юридические лица или индивидуальные предприниматели - производят оплату заказанных услуг платежным поручением на расчетный счет Подрядчика, указанный в счете-фактуре (или Акте сдачи-приемки выполненных работ). </li>
            <li>В соответствие с Постановлением Министерства Финансов Республики Беларусь от 12.02.2018 г. № 13 «О единоличном составлении первичных учетных документов» Заказчик подтверждает и соглашается с  тем, что Акт сдачи-приемки выполненных работ в соответствии с настоящим Договором составляется  Подрядчиком единолично и является подтверждением факта и объема оказанных услуг за отчетный период. Изготовленный в электронном виде Акт заверяется факсимильной подписью Подрядчика и пересылается на адрес электронной почты Заказчика</li>
          </ol>
        </li>
        <li>ВСТУПЛЕНИЕ ДОГОВОРА В СИЛУ
          <ol>
            <li>После проведения переговоров Подрядчик направляет Ценовое Предложение или счет-фактуру с указанием услуг и их стоимости на электронный адрес Заказчика. В случае принятия предложения, Заказчик выполняет пункт 5.2., а так же высылает свои реквизиты на электронный адрес Подрядчика.</li>
            <li>Фактом подтверждения договора Заказчиком является перечисление на банковский счет Подрядчика, указанный в п. 8, с расчетного счета Заказчика суммы в размере 1 (одного) белорусского рубля, а так же указания номера полученного Ценового Предложения или счет-фактуры, в пояснении к платежу.</li>
          </ol>
        </li>
        <li>ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ.
          <ol>
            <li>За невыполнение или ненадлежащее выполнение сторонами настоящего договора Заказчик и Подрядчик несут ответственность, установленную законодательством Республики Беларусь.</li>
            <li>Подрядчик не несет ответственность за работоспособность сетей электропитания и подключения к сети Интернет как Подрядчика, так и Заказчика.</li>
            <li>Ни одна из сторон не будет нести ответственность за полное или частичное неисполнение любой из обязанностей по настоящему Договору вызванное обстоятельствами форс-мажора: стихийное бедствие, аварии, решения органов власти, а также изменения действующего законодательства Республики Беларусь, которые препятствуют выполнению условий настоящего Договора сторонами.</li>
            <li>Все споры и разногласия, возникающие между сторонами по данному договору, разрешаются путем переговоров между сторонами. В случае не достижения согласия споры рассматриваются в Экономическом суде Республики Беларусь в соответствии с действующим законодательством.</li>
          </ol>
        </li>
        <li>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ.
          <ol>
            <li>Договор может быть расторгнут по инициативе одной из сторон в случаях систематического нарушения другой стороной своих обязательств. Сторона, принявшая решение о прекращении действия договора, уведомляет об этом другую сторону в письменной форме.</li>
            <li>Внесение изменений и дополнений в настоящий договор производится двусторонним соглашением в письменной форме.</li>
            <li>В случае возникновения разногласий не предусмотренных настоящим договором применяются нормы действующего гражданского законодательства.</li>
            <li>Настоящий договор вступает в силу с момента его подписания сторонами и действует до момента выполнения сторонами всех своих обязательств.</li>
          </ol>
        </li>
        <li>ЮРИДИЧЕСКИЕ АДРЕСА СТОРОН.
          <ol>
            <li>Реквизитами Заказчика является информация, указанная им при выполнении пункта 5.1.</li>
            <li>Реквизиты Подрядчика:</li>
            
          </ol>
        </li>
      </ol>
      <div>
              <p>Индивидуальный Предприниматель Ярцев Александр Андреевич</p>
              <p>211440 г. Новополоцк ул. Я Коласа 22, к 21</p>
              <p>р/с BY83PJCB30132119701000000933в</p>
              <p>«Приорбанк» ОАО ЦБУ 202 г. Полоцк БИК PJCBBY2X</p>
              <p>УНП 391471287</p>
              <p>e-mail: mail@yartsev.by</p>
              <p>тел.: +375 29 513-38-52 (МТС)</p>
              <p>ИП</p>
              <p>______________ / Ярцев А.А./</p>
              <p>Б.П.</p>
      </div>


    </React.Fragment>
  )
}
