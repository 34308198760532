import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';


import HelpIcon from '@material-ui/icons/Help';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    // backgroundColor: '#0f0',
  },
  listItemIcon: {
    minWidth: theme.spacing(4)
  },
  youtube: {
    color: '#f00'
  }
}),{
  name: 'CalculatorMenuHelp'
});

const CalculatorMenuHelp = ({contacts}) => {
  let classes = useStyles();
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <React.Fragment>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <HelpIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon classes={{root: classes.listItemIcon}}>
            <YouTubeIcon className={classes.youtube}/>
          </ListItemIcon>
          <ListItemText primary="Как пользоваться калькулятором" />
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <ListItemText primary="Есть вопросы?"/>
        </MenuItem>
        <MenuItem component="a" href={`tel:${contacts.displayPhone}` }onClick={handleClose}>
          <ListItemIcon classes={{root: classes.listItemIcon}}>
            <PhoneIcon/>
          </ListItemIcon>
          <ListItemText primary={contacts.displayPhone} secondary="Звоните"/>
        </MenuItem>
        <MenuItem component="a" href={`mailto:${contacts.email}`} onClick={handleClose}>
          <ListItemIcon classes={{root: classes.listItemIcon}}>
            <MailIcon/>
          </ListItemIcon>
          <ListItemText primary={contacts.email} secondary="Пишите"/>
        </MenuItem>
      </Menu>
    </React.Fragment>
          
  )
}

const mapStateToProps = (state) => {
  return {
    contacts: state.data.contacts.support,
  };
};

export default connect(
  mapStateToProps,
  null
)(CalculatorMenuHelp)