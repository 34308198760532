import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
// import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.85)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },

  title: {
    flexBasis: '50%',
    justifyContent: 'center',
    padding: '0 24px',
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 50,
    fontSize: 50,
    margin: 0,
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      paddingTop: 35,
      paddingBottom: 35,
      backgroundColor: fade(theme.palette.primary.main, 0.8),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  actionBlock: {
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'skew(-20deg) translateX(10%)',
    backgroundColor: fade(theme.palette.primary.main, 0.8),

    [theme.breakpoints.down('md')]: {
      transform: 'none',
      paddingTop: 0,
      paddingBottom: 40,
      padding: theme.spacing(0,2,5,2)
    }
  },
  desktopButton: {
    fontSize: 20,
    color: '#fff',
    borderColor: '#fff',
    transform: 'skew(20deg) translateX(-5%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }

  },
  mobileButton: {
    color: theme.palette.primary.main,
    borderColor: '#fff',
    backgroundColor: '#fff',
    fontSize: 18,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      
    },

  }
}),{
  name: 'CallToActionBlock'
});


const CallToActionBlock = ({data}) => {
  let classes = useStyles();
  return (
    <section className={classes.root}>
        {/* <Container className={classes.container}> */}
            <h2 className={classes.title}>{data.title}</h2>
            <div className={classes.actionBlock}>
              <Button className={classes.desktopButton} component={Link} to="/contacts" variant="outlined" size="large">{data.button.title}</Button>
              <Button className={classes.mobileButton} component={Link} to="/contacts" variant="contained" size="large">{data.button.title}</Button>
            </div>
        {/* </Container> */}
        
    </section>
  )
}

export default CallToActionBlock;