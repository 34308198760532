import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { getPriceOneTimeAddEquipment, getPriceSubscribeAddEquipment} from '../../../tools/calculatorTools'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(0,0,1,0),
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: '0.95em'
  },
  list: {

  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    
  },
  listItemTitle: {
    flexGrow: 1
  },
  listItemPrice: {
    fontWeight: 600,
  }
}),{
  name: 'AdditionalEquipmentsListPrice'
});



const AdditionalEquipmentsListPrice = (props) => {
  const classes = useStyles();

  const {
    equipments,
    services
  } = props;
  // console.log('equipments', equipments);
  
  


  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Стоимость</h4>
      <div className={classes.list}>
        <div className={classes.listItem}>
          <span className={classes.listItemTitle}>Оборудования</span>
          <span className={classes.listItemPrice}>{getPriceOneTimeAddEquipment(equipments)} руб.</span>
        </div>
        <div className={classes.listItem}>
          <span className={classes.listItemTitle}>Сервиса</span>
          <span className={classes.listItemPrice}>{getPriceSubscribeAddEquipment(equipments,services)} руб. в месяц</span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    services: state.calculator.data.services
  };
};

export default connect(
  mapStateToProps,
  null
)(AdditionalEquipmentsListPrice)