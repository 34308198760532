import { 
    faYoutube, 
    // faOdnoklassnikiSquare, 
    // faFacebook, 
    // faVk 
} from '@fortawesome/free-brands-svg-icons'

export default {
    name: 'ИП Ярцев А.А.',
    address: {
        postalCode: "211440",
        country: "Беларусь",
        region: "Витебская обл.",
        city: "г. Новополоцк",
        street: "ул. Я. Коласа 22"
    },
    phone: '+375295133852',
    displayPhone: '+375 (29) 513-38-52',
    email: 'info@oodobno.by',
    skype: 'yarick1983',
    telegram: 'yartsevaa',
    viber:'375295133852',
    support: {
        displayPhone: '+375 (29) 513-38-52',
        email: 'support@oodobno.by',
    },
    socials: [
        {
            name: 'YouTube',
            icon: faYoutube,
            mainColor: '#f00',
            link: 'https://www.youtube.com/channel/UCXcjSDdZeP-qyz8fCsRB2Tw'
        },
        // {
        //     name: 'Facebook',
        //     icon: faFacebook,
        //     mainColor: '#3b5998',
        //     link: 'https://www.facebook.com/1udobno/'
        // },
        // {
        //     name: 'Вконтакте',
        //     icon: faVk,
        //     mainColor: '#4680C2',
        //     link: 'https://vk.com/udobnyj_off'
        // },
        // {
        //     name: 'Одноклассники',
        //     icon: faOdnoklassnikiSquare,
        //     mainColor: '#ee8208',
        //     link: 'https://ok.ru/group54989015285878'
        // },
        
    ]
};
