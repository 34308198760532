import App from './components/App';

import ProductPage from './pages/ProductPage';
import ContactsPage from './pages/ContactsPage';
import OfferPage from './pages/OfferPage';
import GatewayPage from './pages/GatewayPage';

import products from './data/products';



export default [
    {
        component: App,
        routes: [

            {
                component: GatewayPage,
                dataItem: products.gateway,
                path: '/',
                exact: true
            },
            {
                component: ProductPage,
                path: '/horeca',
                dataItem: products.horeca,
            },
            
            {
                component: ContactsPage,
                path: '/contacts'
            },
            {
                component: OfferPage,
                path: '/offer'
            },
        ]
    }
]