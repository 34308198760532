
import React, { useRef } from "react";
import { render, hydrate } from "react-dom";

import { Router } from "react-router";
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';

import Routes from './Routes';

import { Provider } from 'react-redux';
import store from './store'
import { syncHistoryWithStore } from 'react-router-redux';

import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './theme';

import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

import { SnackbarProvider } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

const generateClassName = createGenerateClassName({
  productionPrefix: 'o',
  disableGlobal: false
});


const Index = () => {
  const notistackRef = useRef();
  const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
  }

  return (
    <Provider store={store}>
      <SnackbarProvider 
        maxSnack={3}
        autoHideDuration={1000}
        preventDuplicate
        ref={notistackRef}
        action={(key) => (
            <IconButton onClick={onClickDismiss(key)} size="small">
                <CloseIcon fontSize='inherit' style={{color: 'rgba(255,255,255,0.7)'}}/>
            </IconButton>
        )}
        >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <StylesProvider generateClassName={generateClassName}>
            <Router history={history}>
              {renderRoutes(Routes)}
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
};



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Index />, rootElement);
} else {
  render(<Index />, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
