export const getPriceOneTimeCard = (record) => {
  let price_one_time = 0
  record.gadget && record.gadget.forEach(item => {
    price_one_time += item.price
  })
  record.equipments.forEach(item => {
    price_one_time += item.price
  })
  return price_one_time;
}

export const getPriceSubscribeCard = (record,services) => {
  let price_subscribe = 0
  let services_list = record.gadget ? ['pos_interface'] : []
  record.features.forEach(feature => {
    if (feature.check && feature.dependences.services) {
      services_list = [
        ...services_list,
        ...feature.dependences.services
      ]
    }
  })
  let servicesSet = new Set(services_list)
  const servicesOnce = [...servicesSet]
  services.forEach(service => {
    if (servicesOnce.indexOf(service.alias) + 1) {
      price_subscribe += service.price
    }
  })
  return price_subscribe
}


export const getPriceOneTimeAddEquipment = (equipments) => {
  let price_one_time = 0
  equipments.forEach(item => {
    price_one_time += item.count * item.price
  })
  return price_one_time;
}

export const getPriceSubscribeAddEquipment = (equipments,services) => {
  const posServicePrice = services.filter(item => item.alias === "pos_interface")[0].price
  const equipmentsCount = equipments[0] ? equipments[0].count : 0
  const price_subscribe = posServicePrice * equipmentsCount
  return price_subscribe
}