import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 50,
    fontSize: '1.2em',
    '& ol.uppercase > li': {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '1.2rem',
    },
    '& ol': {
      paddingLeft: 0,
      listStyle: 'none',
      counterReset: 'c',
      '& > li': {
        marginTop: 25,
        textAlign: 'left',
        '&:before': {
          counterIncrement: 'c',
          content: 'counters(c,".") ". "',
          marginRight: 5,
        },
        '& li': {
          textTransform: 'none',
          fontWeight: 400,
          marginTop: 10,
          fontSize: '1rem',
        }
      }
    }
  }
}),{
  name: "LegalContent"
});


const LegalContent = (props) => {
  const classes = useStyles(props);

  return (
  <div className={classes.root}>
    {props.children}
  </div>
  );
}

export default LegalContent;