import React, { useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PersonIcon from '@material-ui/icons/Person';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';

import { withSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import { formUrl } from '../config';

const useStyles = makeStyles(theme => ({
  root: {
    // paddingTop: theme.spacing(5),
    // paddingBottom: theme.spacing(5),
    // backgroundColor: '#fff'
    padding: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilySerif,
    marginTop: theme.spacing(2),
    
    fontSize: '2em'
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    
  },
  item: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.light
  },
  itemInput: {
    display: 'flex',
    flexGrow: 1
  },
  submit: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    paddingTop: 8,
    color: theme.palette.common.white
  }
}),{
  name: "ContactsForm"
});


const ContactsForm = ({enqueueSnackbar}) => {
  const classes = useStyles();


  const inputName = useRef();
  const inputPhone = useRef();
  const inputEmail = useRef();
  const inputDescription = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('submit CLICK');
    
    const formData  = {
      name: inputName.current.value,
      phone: inputPhone.current.value,
      email: inputEmail.current.value,
      comment: inputDescription.current.value
    }
    
    fetch(formUrl, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(() => enqueueSnackbar('Запрос отправлен',{variant: 'success'}))
    .catch(() => enqueueSnackbar('Запрос не отправлен',{variant: 'error'}));
    // .then(responseJson => console.log('responseJson',responseJson));

  }

  return (
    <Paper className={classes.root}>
      <h2 className={classes.title}>Свяжитесь с нами</h2>
      <form action="/support_form" method="post" className={classes.form} noValidate autoComplete="off">
        {/* <TextField className={classes.textField} id="outlined-basic" label="Ваше имя" variant="outlined" /> */}
        
        <Grid className={classes.item} container spacing={1} alignItems="flex-end">
          <Grid item>
            <PersonIcon className={classes.icon} />
          </Grid>
          <Grid item className={classes.itemInput}>
            <TextField inputRef={inputName} className={classes.textField} id="contact_name" name="contact_name" color="secondary" label="Ваше имя" fullWidth/>
          </Grid>
        </Grid>

        {/* <Grid className={classes.item} container spacing={1} alignItems="flex-end">
          <Grid item>
            <BusinessIcon className={classes.icon} />
          </Grid>
          <Grid item className={classes.itemInput}>
            <TextField className={classes.textField} id="partner_name" name="partner_name" color="secondary" label="Название компании" fullWidth/>
          </Grid>
        </Grid> */}

        <Grid className={classes.item} container spacing={1} alignItems="flex-end">
          <Grid item>
            <PhoneAndroidIcon className={classes.icon} />
          </Grid>
          <Grid item className={classes.itemInput}>
            <TextField inputRef={inputPhone} className={classes.textField} id="phone" name="phone" color="secondary" label="Номер телефона" fullWidth/>
          </Grid>
        </Grid>

        <Grid className={classes.item} container spacing={1} alignItems="flex-end">
          <Grid item>
            <MailOutlineIcon className={classes.icon} />
          </Grid>
          <Grid item className={classes.itemInput}>
            <TextField inputRef={inputEmail} className={classes.textField} id="email_from" name="email_from" color="secondary" label="Email" fullWidth/>
          </Grid>
        </Grid>

        {/* <FormControl className={classes.item}>
          <InputLabel htmlFor="name" color="secondary">Тема обращения</InputLabel>
          <Input
            id="name"
            name="name"
            color="secondary"
          />
        </FormControl> */}
        
        <FormControl className={classes.item}>
          <InputLabel htmlFor="description" color="secondary">Ваш вопрос</InputLabel>
          <Input
            inputRef={inputDescription}
            id="description"
            name="description"
            color="secondary"
            multiline
            rows={6}
          />
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          startIcon={<SendIcon/>}
          onClick={handleSubmit}
          type="submit"
        >
          Отправить
        </Button>
      </form>
    </Paper>
  );
}

export default withSnackbar(ContactsForm);