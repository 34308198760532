import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';


import logo from '../theme/images/logo-in-header.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexBasis: 220,
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.only('md')]: {
      flexBasis: 70,
      marginBottom: 25
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: 180,
    },
    [theme.breakpoints.only('xs')]: {
      flexBasis: 60,
      marginBottom: 20
    }
  },
  image: {
    width: '100%',
    maxWidth: '100%'
  }
}),{
  name: 'Logo'
});


const Logo = () => {
  let classes = useStyles();
  return (
    <Link className={classes.root} to="/">
      <img className={classes.image} src={logo} alt="О, удобно" />
    </Link>
  )
}

export default Logo;