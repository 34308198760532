"use strick";
import React from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';

import MainTitle from '../components/MainTitle';
import LegalContent from '../components/LegalContent';

import page from '../data/pages/offer';
import {baseUrl} from '../config';

const OfferPage = () => {
  
  return (
    <React.Fragment>
      <Helmet>
          {/* Meta tags */}
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_description} />
          <meta name="keywords" content={page.meta_keywords} />

          {/* Canonical */}
          <link rel="canonical" href={baseUrl + page.url} />

          {/* Open Graph */}
          <html prefix="og: http://ogp.me/ns#" />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:description" content={page.meta_description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={baseUrl + page.url} />
          <meta property="og:image" content={baseUrl + page.url_image} />
      </Helmet>
      <Container>
        <MainTitle text={page.title}/>
        <LegalContent>
          {page.body}
        </LegalContent>
      </Container>
    </React.Fragment>
    
  )
}

export default connect(
  (state, ownProps) => ({
    state: state,
    ownProps
  }),
  null
)(OfferPage);