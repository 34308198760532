import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

// import { fade } from '@material-ui/core/styles/colorManipulator';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    padding: 25,
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  text: {
    fontWeight: 700,
    fontSize: '6em',
    textShadow: '-2px 2px 4px rgba(0,0,0,0.5);',
    color: "rgba(255,255,255,0.9)",
    [theme.breakpoints.down('lg')]: {
      fontSize: '4em',
      marginBottom: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3em',
    }
  },
  moreLink: {
    color: 'rgba(255,255,255,0.85)'
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.only('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  button: {
    borderColor: '#ffffff',
    color: '#ffffff',
    '&:first-child': {
      marginRight: 40,
      [theme.breakpoints.only('lg')]: {
        marginRight: 0,
        marginBottom: 15
      },
      [theme.breakpoints.only('xs')]: {
        marginRight: 0,
        marginBottom: 15
      }
    }
  }
}),{
  name: 'СallToAction'
});


const CallToAction = ({data, calculatorRoot, width}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleOfferClick = () => {
    let coordinateY = calculatorRoot.current.offsetTop + theme.sizes.header.height[width];
    window.scrollTo({
      top: coordinateY,
      behavior: "smooth"
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.priceBlock}>
        <span className={classes.text}>{data.title}</span>
      </div>
      <div className={classes.buttonsBlock}>
        <Button className={classes.button} variant="outlined" size="large" href={data.buttonDemo.action} target="_blank">{data.buttonDemo.title}</Button>
        <Button className={classes.button} variant="outlined" size="large" onClick={handleOfferClick}>{data.buttonOffer.title}</Button>
      </div>
    </div>
  )
}

export default withWidth()(CallToAction);