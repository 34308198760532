import React, { useState, useRef , createRef} from "react";
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { makeStyles, useTheme } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PersonIcon from '@material-ui/icons/Person';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { withSnackbar } from 'notistack';

import { applyTemplate } from '../../store/actions/calculatorActions'

import { formUrl } from '../../config';
import {pdfOfferFileName} from '../../config';

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      minWidth: 500
    }
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(255,255,255,0.3)',
    boxShadow: theme.shadows[4],
    '&:hover, &:focus': {
      backgroundColor: '#f1f1f1'
    }
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    
  },
  item: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.light
  },
  itemInput: {
    display: 'flex',
    flexGrow: 1
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  submit: {
    alignSelf: 'center',
    // paddingTop: 8,
    color: theme.palette.common.white,
  },
  lastCell: {
    borderBottomColor: 'transparent',
    fontWeight: 600,
  },
  TableCell:{
    padding: '0px'
  }


}),{
  name: 'CalculatorOrder'
})


const CalculatorOrder = ({output,services,enqueueSnackbar,resetTemplate}) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const inputName = useRef();
  const inputPhone = useRef();
  const inputEmail = useRef();
  const inputComment = useRef();


  

  const handleClose = () => {
    setOpen(false);
  };

  

  const handlePrev = () => {
    setStep(step - 1)
  }

  

  let equipments_list = []
  let services_list = {
    pos_interface: 0,
    hw_proxy: 0
  }

  const checkIteminList = item => {
    return Boolean(equipments_list.filter(equipment => (equipment.alias === item.alias) && (equipment.type === item.type)).length)
  }

  const addService = gadget => {
    if (gadget.pos_interface) {
      services_list.pos_interface += 1
    }
    if (gadget.hw_proxy) {
      services_list.hw_proxy += 1
    }
  }

  const addEquipment = item => {

    if (!checkIteminList(item)) {
      equipments_list.push({
        title: item.title,
        alias: item.alias,
        type: item.type,
        price: item.price,
        count: item.count ? item.count : 1
      })
    } else {
      equipments_list = equipments_list.map(equipment => {
        if ((equipment.alias === item.alias) && (equipment.type === item.type)) {
            return {
            ...equipment,
            count: item.count ? equipment.count + item.count : equipment.count + 1
            } 
        } else {
            return equipment
        }
      })
    }
  }

  const totalEquipments = list => {
    let total = 0
    list.forEach(item => total += item.price * item.count)
    return total
  }

  const totalServices = list => {
    let total = 0
    services.forEach(item => total += item.price * list[item.alias])
    return total
  }

  const fillLists = () => {
    
    output.workPlaces.forEach(workPlace => {
      workPlace.gadget.forEach(gadget => {
        addEquipment(gadget)
        addService(gadget)
      })
      workPlace.equipments.forEach(equipment => {
        addEquipment(equipment)
      })
    })
    output.productionLines.forEach(productionLine => {
      productionLine.equipments.forEach(equipment => {
        addEquipment(equipment)
      })
    })
    output.additionalEquipments.equipments.forEach(equipment => {
      addEquipment(equipment)
      services_list.pos_interface += Number(equipment.count)
    })
  }
  fillLists();
  
  // console.log('equipments_list',equipments_list);
  // console.log('services_list',services_list);

  

  const handleSubmit = (event) => {
    event.preventDefault();
    pdfGenerator.then((pdf_buffer) => {
      const formData  = {
        name: inputName.current.value,
        phone: inputPhone.current.value,
        email: inputEmail.current.value,
        comment: inputComment.current.value,
        pdf_base64:pdf_buffer.toString('base64'),
        equipments_list,
        services_list
      }
      
      fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        console.log(response)
        response.json()
      })
      .then(() => {
        enqueueSnackbar('Запрос отправлен',{variant: 'success'})
        handleClose()
        resetTemplate()
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar('Запрос не отправлен',{variant: 'error'})
      });
    })

    // .then(()=>{document_generator().then((document_blob) => {console.log(document_blob)})})
    
    // .then(responseJson => console.log('responseJson',responseJson));
  }

  const GenerateOfferTable = () => (
    <React.Fragment>
        <DialogTitle>
          Ваш заказ
          <IconButton aria-label="close" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h3>Оборудование</h3>
          <Table className={classes.table} padding="none" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell align="right">Цена&nbsp;(руб.)</TableCell>
              <TableCell align="right">Кол-во</TableCell>
              <TableCell align="right">Сумма&nbsp;(руб.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipments_list.map((equipment, index) => (
              <TableRow key={`equipment-${index}`}>
                <TableCell component="th" scope="row">
                  {equipment.title}
                </TableCell>
                <TableCell align="right">{equipment.price}</TableCell>
                <TableCell align="right">{equipment.count}</TableCell>
                <TableCell align="right">{equipment.price * equipment.count}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className={classes.lastCell} align="right" colSpan={3}>ИТОГО (единоразово)</TableCell>
              <TableCell className={classes.lastCell} align="right">{totalEquipments(equipments_list)}&nbsp;руб.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
          <h3>Услуги</h3>
          <Table className={classes.table} padding="none" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Услуга</TableCell>
              <TableCell align="right">Цена&nbsp;(руб.)</TableCell>
              <TableCell align="right">Кол-во</TableCell>
              <TableCell align="right">Сумма&nbsp;(руб.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service, index) => (Boolean(services_list[service.alias]) && (
                <TableRow key={`service-${index}`}>
                  <TableCell component="th" scope="row">
                    {service.title}
                  </TableCell>
                  <TableCell align="right">{service.price}</TableCell>
                  <TableCell align="right">{services_list[service.alias]}</TableCell>
                  <TableCell align="right">{service.price * services_list[service.alias]}</TableCell>
                </TableRow>
              )))}
            <TableRow>
              <TableCell className={classes.lastCell} align="right" colSpan={3}>ИТОГО (в месяц)</TableCell>
              <TableCell className={classes.lastCell} align="right">{totalServices(services_list)}&nbsp;руб.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h3>Внедрение</h3>
        <Table className={classes.table} padding="none" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Услуга</TableCell>
              <TableCell align="right">Цена&nbsp;(руб.)</TableCell>
              <TableCell align="right">Кол-во</TableCell>
              <TableCell align="right">Сумма&nbsp;(руб.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">Внедрение системы в вашем заведении</TableCell>
              <TableCell align="right">0</TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.lastCell} align="right" colSpan={3}>ИТОГО</TableCell>
              <TableCell className={classes.lastCell} align="right">0&nbsp;руб.</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </DialogContent>
      </React.Fragment>
  )
  
  const RefGenerateOfferTable = createRef();
  const pdfGenerator = new Promise((resolve, reject) => {

    let pdf_equipments_list = equipments_list.map((equipment, index) => (
        [equipment.title,equipment.price,equipment.count,equipment.price * equipment.count]
    ))
    //TODO Костыльное решение надо будет потом сделать так, чтобы приходил нормальный объект
    if(pdf_equipments_list.length === 0){
      pdf_equipments_list = [[{}, {}, {},{}]]
    }
    console.log('pdf_equipments_list',pdf_equipments_list)
    let pdf_services_list = services.map((service, index) => (Boolean(services_list[service.alias]) && (
        [service.title,service.price,services_list[service.alias],service.price * services_list[service.alias]]
    )))
    //TODO Костыльное решение надо будет потом сделать так, чтобы приходил нормальный объект
    if(pdf_services_list[1] === false){
      pdf_services_list = [[{}, {}, {},{}]]
    }
    console.log('pdf_services_list',pdf_services_list)
      
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
    var pdfExportSetting = {

      content: [
        {
          text: 'ООО Ярцев и Компаньоны',
          style: 'header',
          alignment: 'right'
        },
        {text: 'Оборудование', style: 'subheader'},
        {
          style: 'tableExample',
          table: {
            widths: [300, "*", '*', '*'],
            body: [
              ['Наименование', 'Цена (руб.)', 'Кол-во','Сумма руб.'],
              ...pdf_equipments_list
            ]
          },
        },
        {text: 'Подписка', style: 'subheader'},

        {
          style: 'tableExample',
          table: {
            widths: [300, "*", '*', '*'],
            body: [
              ['Наименование', 'Цена (руб.)', 'Кол-во','Сумма руб.'],
              ...pdf_services_list
            ]
          },
        },
        {text: 'Внедрение', style: 'subheader'},

        {
          style: 'tableExample',
          table: {
            widths: [300, "*", '*', '*'],
            body: [
              ['Наименование', 'Цена (руб.)', 'Кол-во','Сумма руб.'],
              ['Внедрение системы в вашем заведении','0','1','0']
            ]
          },
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'justify'
        }
      }
    };

    
    let my_document = pdfMake.createPdf(pdfExportSetting)
    // pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
    my_document.getBuffer( function (buffer) {
      resolve(buffer)
    });
    // "Это пример как отрендерить React компонент в изображение"
    // const RefGenerateOfferTable = createRef(); 
    // let document_element = RefGenerateOfferTable.current
    // html2canvas(document_element,{
    //   scrollX: 0,
    //   scrollY: 0,
    //   logging: true,
    //   // useCORS: true,
    //   scale: true,
    // }).then(canvas => {
    //   let img = canvas.toDataURL();
    //   var data = img.replace(/^data:image\/\w+;base64,/, "");
    //   var buf = new Buffer(data, 'base64');
    //   const ImageBlob = new Blob([buf], { type: 'Content-Type: image/png' });
    //   saveAs(ImageBlob, 'ImageBlob.png');
    // });
    
  });

  const printToPdf = () => {
    pdfGenerator.then((pdf_buffer) => {
      let pdf_blob = new Blob([pdf_buffer],{type:'Content-Type: application/pdf'});
      saveAs(pdf_blob, pdfOfferFileName);
    })
  }

  const handleClickOpen = () => {
    
    setStep(1);
    setOpen(true);
  };

  const handleNext = () => {
    
    setStep(step + 1)
  }

  return (
    <React.Fragment>
      <IconButton className={classes.button} onClick={handleClickOpen}>
        <ShoppingCartIcon color="inherit" fontSize="small"/>
      </IconButton>
      <Dialog 
        classes={{
          paper: classes.dialog
        }}
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        >
          {step === 1 && (
            <span id="print_to_pdf" ref={RefGenerateOfferTable}>
              <GenerateOfferTable
                // style={{ height: "50vw", width: "500" }}
              />
            </span>
          )
            }
          {step === 2 && (
            <React.Fragment>
              <DialogTitle id="form-dialog-title">
                Заполните форму
                <IconButton aria-label="close" className={classes.close} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid className={classes.item} container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonIcon className={classes.icon} />
                  </Grid>
                  <Grid item className={classes.itemInput}>
                    <TextField 
                      className={classes.textField}
                      inputRef={inputName}
                      id="contact_name"
                      name="contact_name"
                      color="secondary"
                      label="Ваше имя"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid className={classes.item} container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PhoneAndroidIcon className={classes.icon} />
                  </Grid>
                  <Grid item className={classes.itemInput}>
                    <TextField
                      className={classes.textField}
                      inputRef={inputPhone}
                      id="phone"
                      name="phone"
                      color="secondary"
                      label="Номер телефона"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid className={classes.item} container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <MailOutlineIcon className={classes.icon} />
                  </Grid>
                  <Grid item className={classes.itemInput}>
                    <TextField
                      className={classes.textField}
                      inputRef={inputEmail}
                      id="email_from"
                      name="email_from"
                      color="secondary"
                      label="Email"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                
                <FormControl className={classes.item} fullWidth>
                  <InputLabel htmlFor="description" color="secondary">Комментарий</InputLabel>
                  <Input
                    inputRef={inputComment}
                    id="description"
                    name="description"
                    color="secondary"
                    multiline
                    rows={6}
                  />
                </FormControl>
              </DialogContent>
            </React.Fragment>
          )}
        
        <DialogActions className={classes.actions}>
          {step === 1 && (
            <React.Fragment>
              <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowForwardIcon/>}
              type="button"
              onClick={handleNext}
            >
              Далее
            </Button>
            <Button onClick={printToPdf}>
              Сохранить
            </Button>
          </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <Button
                className={classes.prevButton}
                startIcon={<ArrowBackIcon/>}
                type="button"
                onClick={handlePrev}
              >
                Назад
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                startIcon={<SendIcon/>}
                type="submit"
                onClick={handleSubmit}
              >
                Отправить
              </Button>
              
            </React.Fragment>
            
          )}
          
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

}

const mapStateToProps = (state) => {
  return {
    output: state.calculator.output,
    services: state.calculator.data.services
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetTemplate: () => dispatch(applyTemplate('empty')),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CalculatorOrder))
