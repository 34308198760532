import React,  {forwardRef} from "react";
import { makeStyles } from '@material-ui/core/styles';

import ReactMarkdown from 'react-markdown';

import Container from '@material-ui/core/Container';

import SubTitle from './SubTitle';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '100px 0',
    backgroundColor: 'rgba(0,0,0,0.03)',
    [theme.breakpoints.down('md')]: {
      padding: '70px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '25px 0',
    }
  },
  description: {
    color: theme.palette.text.primary

  },
  price: {
    fontWeight: 700,
    color: '#000',
    fontSize: '3em',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6),
    }
  }
}),{
  name: 'Price'
});


const Price = forwardRef((props, calculatorRoot) => {
  const classes = useStyles();

  const data = props.data

  return (
    <section className={classes.root} ref={calculatorRoot}>
      <Container maxWidth="md">
        <SubTitle text={data.title} />
        <div className={classes.price}>{data.price_one_time} руб.</div>
        <div className={classes.description}>
          <ReactMarkdown source={data.description} />
        </div>
      </Container>
    </section>
    
  )
})

export default Price;