import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: '100%',
    fontSize: 32,
    textAlign: "center",
    marginBottom: 50,
    marginTop: 0,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
      marginBottom: 40,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      marginBottom: 35,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      marginBottom: 30,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginBottom: 25,
    },
  },
}),{
  name: 'SubTitle'
});


const SubTitle = ({text}) => {
  let classes = useStyles();
  return (
    <h2 className={classes.root}>{text}</h2>
  )
}

export default SubTitle;