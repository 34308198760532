import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CardEquipmentItem from './CardEquipmentItem';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1,0),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  icon: {
    width: 24,
    height: 24,
    color: 'rgba(0,0,0,0.3)'
  },
  formControl: {
    // flexGrow: 1,
    padding: theme.spacing(0,1)
  },
  equipmentPrice: {

  },
  trashIcon: {
    marginLeft: theme.spacing(1),
    
  },
  replaceTrashIcon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1
  }
}),{
  name: 'CardEquipments'
});



const CardEquipments = (props) => {
  const classes = useStyles();
  
  
  const { 
    itemType,
    item, 
    itemIndex
  } = props;

  return (
    <div className={classes.root}>
      {item.gadget && item.gadget.map((gadget,index) => (
          <CardEquipmentItem 
            key={`gadget-${index}`}
            equipment={gadget} 
            equipmentType='gadget' 
            equipmentIndex={index}
            itemType={itemType} 
            itemIndex={itemIndex}
          />
        )
      )}
      {item.equipments.map((equipment,index) => (
          <CardEquipmentItem 
            key={`equpment-${index}`}
            equipment={equipment} 
            equipmentType='equipments' 
            equipmentIndex={index}
            itemType={itemType} 
            itemIndex={itemIndex}
          />
        )
      )}
      {(!item.gadget && !item.equipments.length) && (
        <div>Нет оборудования</div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    equipmentTypes: state.calculator.data.equipmentTypes,
    equipments: state.calculator.data.equipments,
    // currentValues: state.calculator.output
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // changeEquipment: (itemType,itemIndex,equipmentType,indexEquipment) => {dispatch(toggleFeatureCard({itemType,itemIndex,equipmentType,indexEquipment}))}
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardEquipments)